import React from 'react';
import StyledButton from 'src/components/StyledButton';
import { useNavigate } from 'react-router-dom';

const Verified: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <p className="mt-3 text-center text-base font-normal text-grey-600">
        Congratulations! Your account has been successfully verified. Welcome to
        our community!
      </p>
      <StyledButton onClick={() => navigate(`/sign-in`)}>
        Sign in now
      </StyledButton>
    </>
  );
};

export default Verified;
