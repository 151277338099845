import Cookies from 'js-cookie';

export const removeTokens = () => {
  if (typeof window !== 'undefined') {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
  }
};

export const setTokens = (accessToken: string, refreshToken: string) => {
  if (typeof window !== 'undefined') {
    Cookies.set('access_token', accessToken);
    Cookies.set('refresh_token', refreshToken);
  }
};
