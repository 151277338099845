import baseApi from '../axios';
import { IOrder } from '../types/order';
import { IOrderFormBody, ISearchOrderQuery } from './types';

export const createOrder = async (body: IOrderFormBody) => {
  const resp = await baseApi.post('/orders/web', body);
  return resp?.data;
};

export const getMyOrders = async (searchQuery: ISearchOrderQuery) => {
  const queryStr = new URLSearchParams(searchQuery).toString();
  const resp = await baseApi.get(`/orders/mine?${queryStr}`);
  return resp?.data;
};

export const getOrderDetails = async (orderId: string): Promise<IOrder> => {
  const resp = await baseApi.get(`/orders/${orderId}`);
  return resp?.data?.data;
};

export const getOrderMintHashInput = async (orderId: string) => {
  const resp = await baseApi.get(`/orders/${orderId}/mint`);
  return resp?.data;
}

export const updateOrderMintTransaction = async (orderId: string, orderItemId: string, transactionHash: string) => {
  const resp = await baseApi.post(`/orders/${orderId}/mint/${orderItemId}`, { transactionHash });
  return resp?.data;
}