'use client';

import React from 'react';
import {
  getSmartContractOnStarkscan,
  getSmartContractOnVoyager,
} from 'src/lib/smartContract';

const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http:/www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16667 3.125C5.82995 3.125 3.125 5.82995 3.125 9.16667C3.125 12.5034 5.82995 15.2083 9.16667 15.2083C10.8005 15.2083 12.2829 14.5598 13.3704 13.5061C13.3891 13.4806 13.41 13.4561 13.4331 13.4331C13.4561 13.41 13.4806 13.3891 13.5061 13.3704C14.5598 12.2829 15.2083 10.8005 15.2083 9.16667C15.2083 5.82995 12.5034 3.125 9.16667 3.125ZM14.7457 13.8619C15.8144 12.5933 16.4583 10.9552 16.4583 9.16667C16.4583 5.13959 13.1937 1.875 9.16667 1.875C5.13959 1.875 1.875 5.13959 1.875 9.16667C1.875 13.1937 5.13959 16.4583 9.16667 16.4583C10.9552 16.4583 12.5933 15.8144 13.8619 14.7457L17.0581 17.9419C17.3021 18.186 17.6979 18.186 17.9419 17.9419C18.186 17.6979 18.186 17.3021 17.9419 17.0581L14.7457 13.8619ZM8.54167 5C8.54167 4.65482 8.82149 4.375 9.16667 4.375C11.813 4.375 13.9583 6.5203 13.9583 9.16667C13.9583 9.51184 13.6785 9.79167 13.3333 9.79167C12.9882 9.79167 12.7083 9.51184 12.7083 9.16667C12.7083 7.21066 11.1227 5.625 9.16667 5.625C8.82149 5.625 8.54167 5.34518 8.54167 5Z"
      fill="#959595"
    />
  </svg>
);

const Banner: React.FC = () => {
  return (
    <div
      className="bg-primary-500 text-white mt-[65px] xl:pt-0 bg-no-repeat bg-center bg-cover h-[50vh] md:h-[65vh]"
      style={{ backgroundImage: `url('./banner-bg.svg')` }}
    >
      <div className="w-full h-full flex justify-center items-center mx-auto">
        <div className="flex flex-col items-center">
          <div
            className="text-xl md:text-4xl xl:text-6xl text-center font-bold xl:mt-0"
            style={{ lineHeight: '1.1' }}
          >
            Unlock Your Next Starknet Experiences:
            <br />
            Your Door to Onchained Events
          </div>
          <div className="text-base md:text-xl xl:text-2xl text-center font-normal mt-4 md:mt-8">
            StarkPass onchains your verifiable hashed tickets
          </div>
          <div className="flex flex-col md:flex-row items-center relative mt-2 md:mt-8 gap-2 md:gap-8">
            <a
              className="w-[200px] bg-white text-black px-4 py-2 rounded-lg flex items-center  justify-center text-base font-semibold md:py-[10px] md:px-[20px] hover:bg-slate-200 transition-all"
              href={getSmartContractOnStarkscan()}
              target="_blank"
              rel="noreferrer"
            >
              View on Starkscan
            </a>
            <a
              className="w-[200px] bg-white text-black px-4 py-2 rounded-lg flex items-center justify-center text-base font-semibold md:py-[10px] md:px-[20px] hover:bg-slate-200 transition-all"
              href={getSmartContractOnVoyager()}
              target="_blank"
              rel="noreferrer"
            >
              View on Voyager
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
