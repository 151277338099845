import Footer from 'src/components/Footer';
import { baseURL } from 'src/lib/baseURL';
import React from 'react';
import NavBar from 'src/components/Navbar';
import Container from 'src/components/Container';
import Cookies from 'js-cookie';

async function getData() {
  const access_token = Cookies.get('access_token');

  if (!access_token) return null;

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${access_token}`);

  const res = await fetch(baseURL + '/users/profile', {
    method: 'GET',
    headers: myHeaders,
  });
  if (!res.ok) {
    return null;
  }

  const data = await res.json();
  return data?.data;
}

export default function Layout({ children }: { children: React.ReactNode }) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    getData().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <Container>
      <NavBar user={data} />
      {children}
      <Footer />
    </Container>
  );
}
