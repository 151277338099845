import Events from "src/components/home/Events";

function AllEvents() {
  return (
    <div className="bg-gray-100 px-2.5 xl:px-32 lg:px-14 pt-20 md:pt-32 min-h-screen pb-8">
      <div className="text-black text-3xl	font-bold mb-5">ALL EVENTS</div>
      <Events />
    </div>
  );
}

export default AllEvents;
