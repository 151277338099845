import axios from 'axios';

type IAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

const getCityCountry = async (lat: number, lng: number) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GG_MAP_KEY}`,
  );
  const results = response.data.results;
  if (results[0]?.address_components) {
    const result = results[0].address_components;
    const city =
      result.find(
        (obj: IAddressComponent) =>
          obj?.types?.includes('administrative_area_level_1'),
      )?.long_name || '';
    const country =
      result.find((obj: IAddressComponent) => obj?.types?.includes('country'))
        ?.long_name || '';
    return { city, country };
  }
  throw new Error('Cannot get city and country');
};

export default getCityCountry;
