'use client';

import React from 'react';

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [style, setStyle] = React.useState({
    paddingTop: 0,
    minHeigth: '400px',
  });

  React.useEffect(() => {
    const headerDOM = document.getElementById('tn-header');
    const footerDOM = document.getElementById('tn-footer');
    const footerHeight = footerDOM ? footerDOM.offsetHeight : 0;

    const onResize = () => {
      setStyle((prev) => ({
        ...prev,
        minHeigth: `calc(100vh - ${footerHeight}px)`,
      }));
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div
      className="overflow-auto"
      style={{ paddingTop: style.paddingTop, minHeight: style.minHeigth }}
    >
      {children}
    </div>
  );
};

export default Container;
