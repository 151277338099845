import { ICVerified } from 'src/lib/icons';
import Verified from './components/Verified';

export default function VerifyOtp() {
  return (
    <div className="container mx-auto w-screen min-h-screen">
      <div className="flex flex-row w-full h-screen justify-center mt-20">
        <div className="w-[22rem] lg:w-96">
          <div className="flex flex-column items-center justify-center">
            <ICVerified />
          </div>
          <h2 className="mt-6 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
            Verified!
          </h2>
          <Verified />
        </div>
      </div>
    </div>
  );
}
