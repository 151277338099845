import baseApi from '../axios';
import { LoginForm } from 'src/pages/sign-in/components/utils/types';
import { OauthLoginForm, RegisterBody, ResetPasswordBody } from './types';

export const registerByEmailAndPassword = async (body: RegisterBody) => {
  const resp = await baseApi.post('/auth/register', body);
  return resp?.data;
};

export const loginWithOauth = async (body: OauthLoginForm) => {
  const resp = await baseApi.post('/open-auth/login', body);
  return resp?.data;
};

export const loginWithEmail = async (body: LoginForm) => {
  const resp = await baseApi.post('/auth/login', body);
  return resp?.data;
};
export const sendVerificationToEmail = async (email: string) => {
  const resp = await baseApi.post('/auth/send-verification', { email });
  return resp?.data;
};
export const verifyUserByEmail = async (otpVerificationBody: {
  email: string;
  code: string;
}) => {
  const resp = await baseApi.post('/auth/verify', otpVerificationBody);
  return resp?.data;
};

export const getMyProfile = async () => {
  const resp = await baseApi.get('/users/profile');
  return resp?.data;
};

export const sendResetPasswordEmail = async (email: string) => {
  const resp = await baseApi.post('/auth/forgot-password', { email });
  return resp?.data;
};

export const resetPassword = async (body: ResetPasswordBody) => {
  const resp = await baseApi.put('/auth/reset-password', body);
  return resp?.data;
};
