import { Carousel } from 'flowbite-react';

export default function ImageCarousel({ imageUrls }: { imageUrls: string[] }) {
  return (
    <div className="w-full h-72 xl:h-96">
      <Carousel slideInterval={3000}>
        {imageUrls.map((imageUrl, index) => (
          <img
            key={index}
            alt={`image-${index}`}
            className="rounded-2xl w-full xl:w-auto h-auto xl:h-full object-cover"
            src={imageUrl}
          />
        ))}
      </Carousel>
    </div>
  );
}
