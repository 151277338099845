export const getSmartContractOnStarkscan = () => {
  return (
    'https://sepolia.starkscan.co/contract/' +
    process.env.REACT_APP_STARKNET_CONTRACT_ADDRESS
  );
};

export const getSmartContractOnVoyager = () => {
  return (
    'https://sepolia.voyager.online/contract/' +
    process.env.REACT_APP_STARKNET_CONTRACT_ADDRESS
  );
};
