const ICBack = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http:/www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9423 3.72481C11.1863 3.96888 11.1863 4.36461 10.9423 4.60869L6.17588 9.37508H16.3337C16.6788 9.37508 16.9587 9.6549 16.9587 10.0001C16.9587 10.3453 16.6788 10.6251 16.3337 10.6251H6.17588L10.9423 15.3915C11.1863 15.6356 11.1863 16.0313 10.9423 16.2754C10.6982 16.5194 10.3025 16.5194 10.0584 16.2754L4.22505 10.442C3.98097 10.1979 3.98097 9.80222 4.22505 9.55814L10.0584 3.72481C10.3025 3.48073 10.6982 3.48073 10.9423 3.72481Z"
        fill="#0C0C4F"
      />
    </svg>
  );
};

export default ICBack;
