import useAlert from 'src/hooks/useAlert';
import { useAppSelector } from 'src/redux/store';
import { useEffect } from 'react';

const Alert = () => {
  const { type, message } = useAppSelector((state) => state.alertReducer.value);
  const { removeAlert } = useAlert();

  const chooseStyles = () => {
    const baseStyles =
      'fixed top-5 right-5 z-50 flex justify-between  w-80 px-4 py-3 rounded';
    switch (type) {
      case 'error':
        return (
          baseStyles + ' ' + 'bg-red-100 border border-red-400 text-red-700'
        );
      case 'success':
        return (
          baseStyles +
          ' ' +
          'bg-green-100 border border-green-400 text-green-700'
        );
      case 'warning':
        return (
          baseStyles +
          ' ' +
          'bg-yellow-100 border border-yellow-400 text-yellow-700'
        );
      default:
        return (
          baseStyles + ' ' + 'bg-blue-100 border border-blue-400 text-blue-700'
        );
    }
  };
  const chooseIcon = () => {
    switch (type) {
      case 'error':
        return 'fill-current h-6 w-6 text-red-500';
      case 'success':
        return 'fill-current h-6 w-6 text-green-500';
      case 'warning':
        return 'fill-current h-6 w-6 text-yellow-500';
      default:
        return 'fill-current h-6 w-6 text-blue-500';
    }
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        removeAlert();
      }, 5000);
    }
  }, [message, removeAlert]);

  if (!message) return null;
  return (
    <div className={chooseStyles()} role="alert">
      <div className="block sm:inline">{message}</div>
      <div className="block sm:inline ml-2 w-5" onClick={removeAlert}>
        <svg
          className={chooseIcon()}
          role="button"
          xmlns="http:/www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </div>
    </div>
  );
};

export default Alert;
