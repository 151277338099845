import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (date: string, tz?: string) => {
  return `${new Date(date).toDateString().slice(0, 3)}, ${dayjs(date)
    .tz(tz || dayjs.tz.guess())
    .format('MMM D, HH:mm A')}`;
};
