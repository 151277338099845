const ICInfo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M20.4997 3.33301C11.333 3.33301 3.83301 10.833 3.83301 19.9997C3.83301 29.1663 11.333 36.6663 20.4997 36.6663C29.6663 36.6663 37.1663 29.1663 37.1663 19.9997C37.1663 10.833 29.6663 3.33301 20.4997 3.33301ZM22.1663 26.6663C22.1663 27.6663 21.4997 28.333 20.4997 28.333C19.4997 28.333 18.833 27.6663 18.833 26.6663V19.9997C18.833 18.9997 19.4997 18.333 20.4997 18.333C21.4997 18.333 22.1663 18.9997 22.1663 19.9997V26.6663ZM20.4997 14.9997C19.4997 14.9997 18.833 14.333 18.833 13.333C18.833 12.333 19.4997 11.6663 20.4997 11.6663C21.4997 11.6663 22.1663 12.333 22.1663 13.333C22.1663 14.333 21.4997 14.9997 20.4997 14.9997Z"
        fill="#2970FF"
      />
    </svg>
  );
};

export default ICInfo;
