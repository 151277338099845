import { IMapMarker } from 'src/lib/icons/IMapMarker';
import { EventData } from './types';
import GoogleMapReact from 'google-map-react';
import { IEvent } from 'src/lib/types/event';
import { useMemo } from 'react';

const GoogleMapMarker = ({}: any) => <IMapMarker />; // eslint-disable-line

const GoogleMap: React.FC<{
  event: EventData | Omit<IEvent, 'product' | 'eventPromotions'>;
}> = ({ event }) => {
  const isTBA = useMemo(() => !event?.location || event?.location?.includes("TBA"), [event]);

  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 flex-1">
      <div className="text-black text-2xl font-bold mb-4" >Map</div>
      {
        isTBA && (
          <div style={{ backgroundColor: '#DDDDFF', minHeight: '300px', height: 'calc(100% - 50px)'/*aspectRatio: '1/1'*/ }} className='flex flex-col justify-center items-center text-center h-fit rounded-lg'>
            <img src="/bell.svg" alt="To Be Announced" />
            <p className='text-xl font-bold'>To Be Announced</p>
          </div>
        )
      }
      {
        !isTBA && 
        <div style={{ width: '100%', minHeight: '400px', height: 'calc(100% - 50px)'/*aspectRatio: '1/1'*/ }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GG_MAP_KEY || '',
            }}
            defaultCenter={{
              lat: event?.locationCoordinate?.lat,
              lng: event?.locationCoordinate?.long,
            }}
            defaultZoom={15}
          >
            <GoogleMapMarker
              lat={event?.locationCoordinate?.lat}
              lng={event?.locationCoordinate?.long}
            />
          </GoogleMapReact>
        </div>
      }
    </div>
  );
};

export default GoogleMap;
