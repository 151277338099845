import { Control, Controller } from 'react-hook-form';
import countryCodes from 'src/lib/data/countryCodes';

type Props = {
  control: Control<any>;
  type?: string;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
};
const PhoneInput = ({
  control,
  label,
  name,
  placeholder,
  required = false,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const countryCode = value.substring(1, value.indexOf(')'));
        const phoneNumber = value.substring(value.indexOf(')') + 1);
        return (
          <div>
            <label
              htmlFor={name}
              className="block text-sm font-medium leading-6 text-grey-600"
            >
              {label} {required && <span className="text-grey-600">*</span>}
            </label>
            <div className="flex items-center justify-center my-1">
              <select
                value={countryCode}
                onChange={(e) =>
                  onChange('(' + e.target.value + ')' + phoneNumber)
                }
                className="border border-gray-300 rounded-l-md border-r-0 p-[9px] md:p-[7px]"
              >
                {countryCodes.map((code, index) => (
                  <option
                    key={index}
                    className="text-xs"
                    value={code.dial_code}
                  >
                    {code.code}({code.dial_code})
                  </option>
                ))}
              </select>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) =>
                  onChange('(' + countryCode + ')' + e.target.value)
                }
                className="w-full border border-gray-300 rounded-r-md border-l-0 sm:text-sm"
                style={{ padding: '9px', paddingLeft: '10px' }}
                placeholder={placeholder}
              />
            </div>
            {errors && errors[name] && (
              <span className="font-sans text-red-500 text-xs leading-3 mt-2">
                {errors[name]?.message as string}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default PhoneInput;
