import Container from 'src/components/Container';
import Content from './components/MyTicket';

function MyTicketPage() {
  return (
    <Container>
      <Content />
    </Container>
  );
}

export default MyTicketPage;
