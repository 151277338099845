const ICLocation = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http:/www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_110_32831)">
        <rect x="2" y="1" width="32" height="32" rx="4" fill="#F6F6FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9999 9.2915C14.6632 9.2915 11.9583 11.9964 11.9583 15.3332C11.9583 16.976 12.6998 18.5089 13.9118 20.0825C14.9336 21.4094 16.2416 22.7067 17.6327 24.0865C17.7545 24.2073 17.877 24.3287 17.9999 24.4509C18.1229 24.3287 18.2453 24.2073 18.3671 24.0865C19.7582 22.7067 21.0662 21.4094 22.0881 20.0825C23.3 18.5089 24.0416 16.976 24.0416 15.3332C24.0416 11.9964 21.3366 9.2915 17.9999 9.2915ZM10.7083 15.3332C10.7083 11.3061 13.9728 8.0415 17.9999 8.0415C22.027 8.0415 25.2916 11.3061 25.2916 15.3332C25.2916 17.3723 24.3665 19.1727 23.0784 20.8452C22.0008 22.2445 20.6215 23.6119 19.2315 24.99C18.968 25.2512 18.7041 25.5128 18.4419 25.7751C18.1978 26.0192 17.8021 26.0192 17.558 25.7751C17.2957 25.5128 17.0318 25.2512 16.7683 24.99C15.3783 23.6119 13.999 22.2445 12.9214 20.8452C11.6334 19.1727 10.7083 17.3723 10.7083 15.3332ZM17.9999 13.4582C16.9644 13.4582 16.1249 14.2976 16.1249 15.3332C16.1249 16.3687 16.9644 17.2082 17.9999 17.2082C19.0355 17.2082 19.8749 16.3687 19.8749 15.3332C19.8749 14.2976 19.0355 13.4582 17.9999 13.4582ZM14.8749 15.3332C14.8749 13.6073 16.274 12.2082 17.9999 12.2082C19.7258 12.2082 21.1249 13.6073 21.1249 15.3332C21.1249 17.0591 19.7258 18.4582 17.9999 18.4582C16.274 18.4582 14.8749 17.0591 14.8749 15.3332Z"
          fill="#303093"
        />
        <rect
          x="2.5"
          y="1.5"
          width="31"
          height="31"
          rx="3.5"
          stroke="#DDDDFF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_110_32831"
          x="0"
          y="0"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_110_32831"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_110_32831"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ICLocation;
