import { ICMail } from 'src/lib/icons';
import VerifyCodeForm from './components/VerifyCodeForm';

export default function VerifyOtp() {
  return (
    <div className="container mx-auto w-screen min-h-screen">
      <div className="flex flex-row w-full h-screen justify-center mt-20">
        <div className="w-[22rem] lg:w-96">
          <div className="flex flex-column items-center justify-center">
            <ICMail />
          </div>
          <VerifyCodeForm />
        </div>
      </div>
    </div>
  );
}
