'use client';

import React from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendResetPasswordEmail } from 'src/lib/api/auth';
import useAlert from 'src/hooks/useAlert';
import { ForgotPasswordFormValidation } from './utils/validation';
import { IForgotPasswordForm } from './utils/types';
import StyledButton from 'src/components/StyledButton';
import { ICBack } from 'src/lib/icons';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { successAlert, errorAlert } = useAlert();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(ForgotPasswordFormValidation),
  });

  const onSubmit = handleSubmit(async (form: IForgotPasswordForm) => {
    try {
      await sendResetPasswordEmail(form.email);
      successAlert('New password sent to your email');
      navigate('/reset-password');
    } catch (e) {
      errorAlert(e);
    }
  });

  return (
    <>
      <form onSubmit={onSubmit} className="space-y-4">
        <Input type="email" label="Email" name="email" control={control} />
        <StyledButton type="submit">Reset password</StyledButton>
      </form>
      <div className="mt-5 flex flex-row justify-center text-center text-sm text-grey-600 font-normal gap-1">
        <ICBack />
        <button
          className="text-sm text-primary-600 hover:underline"
          onClick={() => navigate('/sign-in')}
        >
          Back to login
        </button>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
