import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'src/lib/types/user';
import { removeTokens } from 'src/lib/utils/auth';

type InitialState = {
  user: IUser | null;
  loading: boolean;
};
const initialState: InitialState = {
  user: null,
  loading: true,
};

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeUser: () => {
      removeTokens();
      return initialState;
    },
    setUser: (_, action: PayloadAction<IUser>) => {
      return {
        user: action.payload,
        loading: false
      };
    },
    doneLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },
  },
});

export const { removeUser, setUser, doneLoading } = userSlice.actions;
export default userSlice.reducer;
