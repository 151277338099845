import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OtpFormValidation } from './utils/validation';
import { OtpForm } from './utils/types';
import OtpInput from './OtpInput';
import useAlert from 'src/hooks/useAlert';
import { sendVerificationToEmail, verifyUserByEmail } from 'src/lib/api/auth';
import StyledButton from 'src/components/StyledButton';
import { useNavigate, useSearchParams } from 'react-router-dom';

const NumberOfDigits = 6;

const VerifyCodeForm: React.FC = () => {
  const inputRefs = React.useRef<HTMLInputElement[]>([]);
  const navigate = useNavigate();
  const { successAlert, errorAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const parsedEmail = email ? decodeURIComponent(email) : '';
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OtpForm>({
    resolver: yupResolver(OtpFormValidation),
  });

  const onSubmit = handleSubmit(async (form: OtpForm) => {
    try {
      const code = `${form.digit1}${form.digit2}${form.digit3}${form.digit4}${form.digit5}${form.digit6}`;
      await verifyUserByEmail({ email: parsedEmail, code: code });
      successAlert('Account verified successfully!');
      navigate('/verified');
    } catch (e) {
      errorAlert(e);
    }
  });

  const handleResendEmail = async () => {
    try {
      await sendVerificationToEmail(parsedEmail);
      successAlert('Email sent successfully!');
    } catch (e) {
      errorAlert(e);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center text-center space-y-2 mb-10">
        <p className="mt-6 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
          Verify your email
        </p>
        <p className="mt-3 text-center text-base font-normal text-grey-600">
          We have sent a verification code to <br />
          {parsedEmail}
        </p>
      </div>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-center w-full">
            <div className="flex flex-row items-center justify-between mx-0 max-w-sm gap-1">
              {Array.from({ length: NumberOfDigits }).map((_, index) => (
                <OtpInput
                  key={index}
                  name={`digit${index + 1}`}
                  control={control}
                  ref={(element) => {
                    inputRefs.current[index] = element!;
                  }}
                  moveNext={() => {
                    if (index < NumberOfDigits - 1) {
                      inputRefs.current[index + 1].focus();
                    }
                  }}
                />
              ))}
            </div>
          </div>
          {errors && Object.keys(errors)?.length > 0 && (
            <p className="block w-200 font-sans text-red-500 text-xs leading-3 mt-5 whitespace-normal text-center">
              Please enter a valid OTP code
            </p>
          )}
          <div className="flex flex-col space-y-5">
            <StyledButton type="submit">Submit</StyledButton>

            <div className="mt-3 flex flex-row items-center justify-center text-center text-sm space-x-1 text-grey-600">
              <p className="font-normal">Didn’t receive the email?</p>{' '}
              <button
                className="flex flex-row items-center font-medium text-grey-600 border-0"
                onClick={handleResendEmail}
              >
                Click to resend
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default VerifyCodeForm;
