import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'src/hooks/useUser';
import useAlert from 'src/hooks/useAlert';

export default function Protected({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { user, loading } = useUser();
  const { errorAlert } = useAlert();

  useEffect(() => {
    if (loading === false && !user) {
      errorAlert('Please login to continue');
      navigate('/sign-in');
    }
  }, [loading]);

  return <>{children}</>;
}
