import { baseURL } from '../baseURL';
import { IEvent } from '../types/event';

export const getEvents = async (
  filter?: Record<string, any>,
): Promise<IEvent[]> => {
  let url = `${baseURL}/events`;

  if (filter) {
    url += `?${new URLSearchParams(filter)}`;
  }

  return fetch(url, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((data) => data.data);
};

export const getEvent = async (id: string): Promise<IEvent> => {
  return fetch(`${baseURL}/events/${id}`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((data) => data.data);
};
