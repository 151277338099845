import ImageCarousel from 'src/components/Carousel';
import useAlert from 'src/hooks/useAlert';
import { formatDate } from 'src/lib/utils/date';
import { useEffect, useState, useMemo } from 'react';
import {
  CalendarIcon,
  LocationIcon,
} from '../../../components/event/EventIcons';
import { ICInfo, ICLoading, ICQRCode } from 'src/lib/icons';
import { useParams } from 'react-router-dom';
import { getImageURL } from 'src/lib/utils/image';
import Speakers from '../../../components/event/Speakers';
import Schedules from '../../../components/event/Schedules';
import GoogleMap from '../../../components/event/GoogleMap';
import About from '../../../components/event/About';
import { IOrder } from 'src/lib/types/order';
import { getOrderDetails, getOrderMintHashInput, updateOrderMintTransaction } from 'src/lib/api/order';
import Modal from 'src/components/Modal';
import StyledButton from 'src/components/StyledButton';
import { generateQRImage } from 'src/lib/utils/qr';
import Sponsors from 'src/components/event/Sponsors';
import { connect } from 'get-starknet';
import { Contract } from 'starknet';

type IOrderWithImages = IOrder & {
  imageUrls: string[];
};

const OrderDetail = () => {
  const params = useParams();
  const { errorAlert } = useAlert();
  const [order, setOrder] = useState<IOrderWithImages | null>(null);
  const [showORCode, setShowORCode] = useState<boolean>(false);
  const [showMintedModal, setShowMintedModal] = useState<boolean>(false);
  const [qrs, setQrs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const orderId = params.id as string;

  // MAPPED DATA
  const productOption = order?.items
    ? order?.items[0]?.productOptionInfo
    : null;
  const event = order?.metadata?.eventInfo;
  const performanceDetail = event?.information?.performanceDetail;
  const speakers = performanceDetail?.speakers || [];
  const schedules = performanceDetail?.schedules || [];
  const sponsors = performanceDetail?.sponsors || [];
  const time = productOption?.options[1]?.label || '';
  const isFinished = new Date(time).getTime() < new Date().getTime() || false;

  const handleShowQRCode = () => setShowORCode(true);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId]);

  const getOrder = (orderId: string) => {
    setLoading(true);
    getOrderDetails(orderId)
      .then((order: IOrder) => {
        if (order) {
          let photoUrls: string[] = [];
          if (order?.metadata?.eventInfo?.photos) {
            const photos = order?.metadata?.eventInfo?.photos.map((photo) =>
              getImageURL(photo.key),
            );
            photoUrls = photos || [];
          }
          if (order?.items && order?.items.length > 0) {
            const approvedItems = order.items.filter(
              (item) => item.subStatus === 'approved',
            );
            Promise.all(
              approvedItems.map(async (item) => {
                return await generateQRImage(
                  JSON.stringify({ ticketId: item.id }),
                );
              }),
            ).then((result) => {
              setQrs(result);
            });
          }
          setOrder({ ...order, imageUrls: photoUrls });
        }
      })
      .catch((e) => {
        errorAlert(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const ticketToMint = useMemo(() => !order?.metadata.nft ? null : order?.items?.find(i => i.mintedTransactionHash) || order?.items?.find(i => i.subStatus === 'verified'), [order]);
  const isMinted = useMemo(() => !!ticketToMint?.mintedTransactionHash, [ticketToMint]);

  const stringToHex = (str: string) => {
    if (str.length === 36) {
      str = str.replace(/-/g, '');
      return `0x${str}`;
    }
    let hex = '';
    for (let i = 0; i < str.length; i++) {
      hex += str.charCodeAt(i).toString(16);
    }
    return `0x${hex}`;
  }

  const handleMint = () => {
    if (!ticketToMint)  return;

    setLoading(true);
    getOrderMintHashInput(orderId)
      .then(async ({ data: { orderItemId, hashedInput } }) => {
        const connectedWallet = await connect();
        const provider = connectedWallet?.provider;
        const account = connectedWallet?.account;
        const contractAddress = process.env.REACT_APP_STARKNET_CONTRACT_ADDRESS || '';
        const { abi } = await provider.getClassAt(contractAddress);
        const contract = new Contract(abi, contractAddress, provider);
        contract.connect(account);
        const { transaction_hash } = await contract.invoke('mint_poa', [stringToHex(ticketToMint.productInfo.code), hashedInput], {
          nonce: await account.getNonce()
        });
        await updateOrderMintTransaction(orderId, orderItemId, transaction_hash);
        getOrder(orderId);
      })
      .catch((e) => {
        errorAlert(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (!order || !event) {
    return (
      <div
        className="flex flex-row justify-center items-center bg-gray-100"
        style={{ minHeight: '90vh', padding: '159px' }}
      >
        {loading ? (
          <ICLoading w={10} h={10} />
        ) : (
          <p className="text-black text-lg font-bold">No data</p>
        )}
      </div>
    );
  }

  return (
    <div className="bg-gray-100 px-2.5 xl:px-32 lg:px-14 pt-20 md:pt-32 min-h-screen pb-8">
      {showORCode && (
        <Modal>
          <div className="px-5 lg:px-20 py-10 lg:py-10">
            <div className="flex flex-col items-center gap-5">
              <ICInfo />
              <p className="text-base">Here's your QR code</p>
            </div>
            <div
              className={`w-full ${
                qrs.length > 1
                  ? 'flex flex-col justify-center items-center md:grid md:grid-cols-2 gap-8'
                  : 'flex flex-row justify-center items-center'
              } rounded-3xl p-6 lg:p-10 xl:p-16 h-[300px] overflow-auto`}
            >
              {qrs.map((qr, index) => (
                <div key={index}>
                  <h6
                    className={`${
                      qrs.length > 1 ? 'text-left' : 'text-center'
                    } text-xl font-semibold mb-4`}
                  >
                    Ticket {index + 1}
                  </h6>
                  <img src={qr} alt={`Ticket ${index + 1}`} />
                </div>
              ))}
            </div>
            <StyledButton onClick={() => setShowORCode(false)}>OK</StyledButton>
          </div>
        </Modal>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 rounded-3xl bg-primary-500 p-6 lg:p-10 xl:p-16 mb-8 flex gap-8 w-full">
        {order.imageUrls && order.imageUrls.length > 0 && (
          <div className="rounded-2xl relative">
            <ImageCarousel imageUrls={order.imageUrls} />
            {/* <span className={`absolute right-2 top-2 px-3 py-1 ${isFinished ? "bg-red-100 text-red-300" : "bg-blue-100 text-blue-400"} rounded-full px-2 py-1 text-base font-semibold`}>
              {isFinished ? "Finished" : "Upcoming"}
            </span> */}
          </div>
        )}
        <div className="text-white flex-1">
          <div className="text-xl md:text-4xl font-semibold mb-4">
            {event?.name}
          </div>
          <div className="font-normal	text-base md:text-lg flex items-center gap-4 mb-4">
            <div className="bg-primary-100 p-1 rounded">
              <LocationIcon />
            </div>
            {event?.location}
          </div>
          <div className="font-normal	text-base md:text-lg flex items-center gap-4 mb-5">
            <div className="bg-primary-100 p-1 rounded">
              <CalendarIcon />
            </div>
            {productOption &&
              formatDate(
                productOption.options[1].label!,
                productOption.options[1].timeZone,
              )}
          </div>
          <div className="block gap-7 items-center">
            <button
              className={`px-5 md:px-2 xl:px-5 py-3 md:py-2 xl:py-3 bg-white rounded-lg flex gap-2 justify-center items-center mt-7 md:mt-0 ${
                qrs.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'
              }}`}
              onClick={handleShowQRCode}
              disabled={qrs.length === 0}
            >
              <ICQRCode />
              <div
                className={`${
                  qrs.length === 0 ? 'text-gray-400' : 'text-primary-600'
                } font-semibold text-base md:text-xs xl:text-base`}
              >
                Show QR Code
              </div>
            </button>
            {order?.items?.every((item) => item.subStatus === 'pending') && (
              <p className="text-white font-normal text-base md:text-xs xl:text-base mt-5">
                * Note: The order is waiting for approval
              </p>
            )}
            {
              (ticketToMint) && (
                <button
                className={`mt-5 px-5 md:px-2 xl:px-5 py-3 md:py-2 xl:py-3 text-primary-600 font-semibold rounded-lg flex gap-2 justify-center items-center mt-7 ${
                  (loading || isMinted) ? 'cursor-not-allowed' : 'cursor-pointer bg-white'
                }}`}
                style={{ backgroundColor: loading ? '#bfbdbd' : 'white' }}
                onClick={!isMinted ? handleMint : () => setShowMintedModal(true)}
                disabled={loading}
                >
                  <img width={22} src="/nft.png" />
                  {isMinted ? 'Show minted NFT' : 'Mint NFT'}
                </button>
              )
            }
            {
              (isMinted && showMintedModal) && (
                <Modal>
                  <div className="px-5 lg:px-20 py-10 lg:py-10 text-black">
                    <div className='flex flex-col items-center'>
                      <img className='max-w-sm' src={order.metadata.nft?.imageUri} />
                      <p className={`mt-2 break-words`}>Transaction: {ticketToMint?.mintedTransactionHash}</p>
                    </div>
                    <StyledButton onClick={() => setShowMintedModal(false)}>OK</StyledButton>
                  </div>
                </Modal>
              )
            }
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <About event={event} />
        <GoogleMap event={event} />
      </div>
      {speakers && speakers.length > 0 && <Speakers speakers={speakers} />}
      {schedules && schedules.length > 0 && <Schedules schedules={schedules} />}
      {sponsors && sponsors.length > 0 && <Sponsors sponsors={sponsors} />}
    </div>
  );
};

export default OrderDetail;
