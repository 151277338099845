const ICStar = ({
  width = 60,
  height = 60,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http:/www.w3.org/2000/svg"
    >
      <g id="Stars">
        <path
          id="Star"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 40C25.4247 40 40 25.4247 40 0C40 25.4247 54.5753 40 80 40C54.5753 40 40 54.5753 40 80C40 54.5753 25.4247 40 0 40Z"
          fill="#FFF9BE"
        />
        <path
          id="Star_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C7.62742 12 12 7.62742 12 0C12 7.62742 16.3726 12 24 12C16.3726 12 12 16.3726 12 24C12 16.3726 7.62742 12 0 12Z"
          fill="#FEC84B"
        />
        <path
          id="Star_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64 24C69.0849 24 72 21.0849 72 16C72 21.0849 74.9151 24 80 24C74.9151 24 72 26.9151 72 32C72 26.9151 69.0849 24 64 24Z"
          fill="#FEC84B"
        />
      </g>
    </svg>
  );
};

export default ICStar;
