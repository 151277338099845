'use client';

import React from 'react';
import { ISpeaker } from 'src/lib/types/event';
import { getImageURL } from 'src/lib/utils/image';

const Member = ({ speaker }: { speaker: ISpeaker }) => (
  <div className="text-center">
    {speaker?.avatar ? (
      <img
        className="rounded-full w-24 h-24 mx-auto mb-5"
        src={getImageURL(speaker.avatar || '')}
      />
    ) : (
      <div className="rounded-full w-24 h-24 mx-auto mb-5 bg-gray-100 text-3xl flex flex-row justify-center items-center text-primary-400">
        {speaker?.name?.[0] || ''}
      </div>
    )}
    <div className="text-black text-lg font-semibold">
      {speaker?.name || ''}
    </div>
    <div className="text-gray-400 font-normal">
      {speaker?.title || ''}
    </div>
  </div>
);

type Props = {
  speakers: ISpeaker[];
};
const Speakers: React.FC<Props> = ({ speakers }) => {
  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 flex-1 mt-8">
      <div className="text-black text-3xl text-center	font-bold mb-5">
        Speakers
      </div>
      <div
        className={
          speakers.length >= 4
            ? 'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'
            : speakers.length == 2
            ? 'flex flex-row justify-between items-center px-[5%] md:px-[20%] lg:px-[30%] xl:px-[35%]'
            : speakers.length == 3
            ? 'grid grid-cols-2 gap-4 md:flex md:flex-row md:justify-between md:items-center md:px-[20%]'
            : 'flex flex-row justify-center'
        }
      >
        {speakers.map((item, index) => (
          <Member key={index} speaker={item} />
        ))}
      </div>
    </div>
  );
};

export default Speakers;
