const ICVerified = ({
  width = 60,
  height = 60,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http:/www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_110_41229)">
        <rect x="2" y="1" width="56" height="56" rx="12" fill="#F6F6FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3739 20.2929C41.7645 20.6834 41.7645 21.3166 41.3739 21.7071L26.7073 36.3738C26.3167 36.7643 25.6836 36.7643 25.2931 36.3738L18.6264 29.7071C18.2359 29.3166 18.2359 28.6834 18.6264 28.2929C19.0169 27.9024 19.6501 27.9024 20.0406 28.2929L26.0002 34.2525L39.9597 20.2929C40.3502 19.9024 40.9834 19.9024 41.3739 20.2929Z"
          fill="#303093"
        />
        <rect
          x="2.5"
          y="1.5"
          width="55"
          height="55"
          rx="11.5"
          stroke="#DDDDFF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_110_41229"
          x="0"
          y="0"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_110_41229"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_110_41229"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ICVerified;
