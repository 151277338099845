import { IEvent } from 'src/lib/types/event';
import { EventData } from './types';

const About: React.FC<{
  event: EventData | Omit<IEvent, 'product' | 'eventPromotions'>;
}> = ({ event }) => {
  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 flex-1">
      <div className="text-black text-2xl font-bold mb-4">About event</div>
      {event?.information?.additionalInformation && (
        <div
          className="text-gray-600"
          dangerouslySetInnerHTML={{
            __html: event.information.additionalInformation,
          }}
        />
      )}
    </div>
  );
};
export default About;
