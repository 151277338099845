'use client';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormValidation } from './utils/validation';
import { LoginForm } from './utils/types';
import { loginWithEmail } from 'src/lib/api/auth';
import useAlert from 'src/hooks/useAlert';
import { setTokens } from 'src/lib/utils/auth';
import StyledButton from 'src/components/StyledButton';
import GoogleLogin from 'src/components/GoogleLogin';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SignInForm = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnURL = searchParams.get('returnURL');

  const { successAlert, errorAlert } = useAlert();
  const { control, handleSubmit } = useForm<LoginForm>({
    resolver: yupResolver(LoginFormValidation),
  });

  // Handlers
  const onSubmit = handleSubmit(async (form: LoginForm) => {
    try {
      const { data } = await loginWithEmail(form);
      if (typeof window === 'undefined') return;
      setTokens(data.accessToken, data.refreshToken);
      successAlert('Logged In successfully!');
      navigate(returnURL ? decodeURIComponent(returnURL) : '/');
    } catch (e) {
      errorAlert(e);
    }
  });

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <>
      <GoogleLogin />
      <div className="flex flex-row w-full items-center justify-between mt-5">
        <div className="border-t w-2/5 border-gray-200"></div>
        <span className="text-gray-400">or</span>
        <div className="border-t w-2/5 border-gray-200"></div>
      </div>
      <form onSubmit={onSubmit} className="space-y-4 mt-3">
        <Input
          type="email"
          label="Email"
          name="email"
          placeholder="input your email"
          control={control}
          required
        />
        <Input
          type="password"
          label="Password"
          name="password"
          placeholder="input your password"
          control={control}
          required
        />
        <StyledButton type="submit">Submit</StyledButton>
      </form>

      {/* Terms and conditions agreement */}
      <div className="text-center mt-4">
        By signing in, we assume you agree to our{' '}
        <span
          className="text-blue-600 cursor-pointer hover:underline"
          onClick={() => navigate('/terms-and-conditions')}
        >
          terms and use
        </span>.
      </div>

      <div className="flex justify-end mt-5">
        <button
          onClick={handleForgotPassword}
          className="text-sm text-primary-600 hover:underline"
        >
          Forgot password?
        </button>
      </div>
      <p className="mt-5 text-center text-sm text-grey-600 font-normal">
        Don’t have an account?{' '}
        <button
          className="text-sm text-primary-600 hover:underline"
          onClick={() => navigate('/register')}
        >
          Sign up!
        </button>
      </p>
    </>
  );
};

export default SignInForm;
