import * as yup from 'yup';

export const OtpFormValidation = yup.object().shape({
  digit1: yup.number().min(0).max(9).required(),
  digit2: yup.number().min(0).max(9).required(),
  digit3: yup.number().min(0).max(9).required(),
  digit4: yup.number().min(0).max(9).required(),
  digit5: yup.number().min(0).max(9).required(),
  digit6: yup.number().min(0).max(9).required(),
});
