export enum PromotionUnit {
  PERCENT = 'percentage',
  NUMBER = 'number',
}

export interface ISpeaker {
  name?: string;
  avatar?: string;
  title?: string;
}

export interface ISponsor {
  name?: string;
  avatar?: string;
}

export interface ISchedule {
  time?: string;
  content?: string;
  speakers?: string;
}

export interface IProductOption {
  id: string;
  optionDate: string;
  optionTime: string;
  price: number;
  currency: string;
  options: {
    timeZone?: string;
    label: string;
    level: number;
    levelName: string;
  }[];
}

export interface IProduct {
  id: string;
  type: string;
  about: string;
  mainPhoto: string;
  title: string;
  price: number;
  options: IProductOption[];
  code: string;
  maxOrder: number;
  quantity: number;
}

export interface IEventInputField {
  key: string;
  label: string;
  type: 'text';
  required?: boolean;
}

export interface IEvent {
  id: string;
  name: string;
  status: string;
  location: string;
  startAt: string;
  banner: string;
  requireAdminApproval: boolean;
  photos: {
    key: string;
  }[];
  product: IProduct;
  locationCoordinate: {
    lat: number;
    long: number;
  };
  eventPromotion: {
    id: string;
    promotion: {
      value: number;
      unit: PromotionUnit;
    };
  }[];
  information: {
    additionalInformation?: string;
    performanceDetail?: {
      schedules?: ISchedule[];
      speakers?: ISpeaker[];
      sponsors?: ISponsor[];
    };
  };
  metadata?: {
    buyerInputs?: IEventInputField[]
  }
}
