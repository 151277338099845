import * as QRCode from 'qrcode';

export const generateQRImage = async (str: string): Promise<string> => {
  const qr = await QRCode.toDataURL(str, {
    width: 200,
    margin: 2,
    scale: 10,
  });
  return qr;
};
