import { getEvents } from 'src/lib/api/events';
import { getPicture } from 'src/lib/api/pics';
import { IEvent, PromotionUnit } from 'src/lib/types/event';
import { formatDate } from 'src/lib/utils/date';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getCityCountry from 'src/lib/api/googleMap';
import { getImageURL } from 'src/lib/utils/image';

type EventData = IEvent & {
  imageUrl?: string;
};

const Event = ({
  event: {
    startAt,
    location,
    name,
    id,
    imageUrl,
    product,
    eventPromotion,
    locationCoordinate,
  },
}: {
  event: EventData;
}) => {
  const [locationName, setLocationName] = useState<string>(location);
  const navigate = useNavigate();
  const currency = product?.options ? product?.options[0].currency : 'USD';

  /*FUNCTIONS*/
  const retrievePrice = () => {
    const price = product?.options ? product?.options[0].price : 0;
    const promotionValue =
      eventPromotion && eventPromotion.length > 0
        ? eventPromotion[0].promotion.value
        : 0;
    const promotionUnit =
      eventPromotion && eventPromotion.length > 0
        ? eventPromotion[0].promotion.unit
        : 'number';
    if (promotionUnit === PromotionUnit.PERCENT) {
      return price - (price * promotionValue) / 100;
    }
    return price - promotionValue;
  };

  const price = retrievePrice();

  useEffect(() => {
    if (!locationCoordinate?.lat || !locationCoordinate?.long) return;
    getCityCountry(locationCoordinate.lat, locationCoordinate.long)
      .then((data) => {
        if (data?.city && data?.country) {
          setLocationName(`${data.city}, ${data.country}`);
        } else if (data?.country) {
          setLocationName(data.country);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [locationCoordinate]);

  return (
    <div
      className="rounded-xl border cursor-pointer"
      onClick={() => navigate(`/events/${id}`)}
    >
      <img className="rounded-t-xl" src={imageUrl} />
      <div className="p-4">
        <div className="text-black text-lg font-bold line-clamp-2 mb-2">
          {name}
        </div>
        <div className="text-gray-600 text-sm font-normal mb-2">
          {locationName}
        </div>
        <div className="text-primary-500 text-sm font-normal mb-2">
          {formatDate(
            product?.options[0].options[1].label,
            product?.options[0].options[1].timeZone,
          )}
        </div>
        <div className="text-second-400 text-md font-semibold mb-2">
          {price === 0
            ? 'FREE'
            : `${(currency || '').toLocaleUpperCase()} ${price}`}{' '}
        </div>
      </div>
    </div>
  );
};

interface Props {
  upcoming?: boolean;
  limit?: number;
}

const Events: React.FC<Props> = ({ upcoming = false, limit = 20 }) => {
  const [events, setEvents] = useState<EventData[]>([]);

  useEffect(() => {
    const filter: Record<string, unknown> = {};
    if (upcoming) {
      filter['startFrom'] = new Date().toISOString();
    }
    getEvents(filter).then(async (events) => {
      await Promise.all(
        events.map(async (event: EventData) => {
          event.imageUrl = getImageURL(event.banner);
        }),
      );

      setEvents(events.slice(0, limit));
    });
  }, []);

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {events.map((event) => (
          <Event key={event.id} event={event} />
        ))}
      </div>
    </div>
  );
};

export default Events;
