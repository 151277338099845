import React from 'react';
import { ICTimeSpot } from 'src/lib/icons';
import { ISchedule } from 'src/lib/types/event';

type Props = {
  schedules: ISchedule[];
};
const Schedules: React.FC<Props> = ({ schedules }) => {
  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 flex-1 mt-8">
      <div className="text-black text-3xl text-center	font-bold mb-10">
        Agenda
      </div>
      <div className="w-[300px] md:w-full overflow-x-auto">
        <div className="grid grid-cols-[1fr_2fr_1fr] mb-10">
          <div className="text-black text-lg font-semibold min-w-[150px]">
            Time
          </div>
          <div className="text-black text-lg font-semibold min-w-[300px]">
            Topic
          </div>
          <div className="text-black text-lg font-semibold min-w-[300px]">
            Speakers
          </div>
        </div>
        {schedules.map((item, index) => (
          <React.Fragment key={index}>
            <div className="grid grid-cols-[1fr_2fr_1fr] my-2">
              <div className="flex flex-row items-center gap-2 min-w-[150px]">
                <ICTimeSpot />
                {item?.time || ''}
              </div>
              <div className="text-primary-400 text-base min-w-[300px]">
                {item?.content || ''}
              </div>
              <div className="text-black text-base font-normal min-w-[300px]">
                {item?.speakers || ''}
              </div>
            </div>
            {schedules.length - 1 > index && (
              <div className="bg-black h-8 w-[1px] ml-[10px]"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Schedules;
