import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertState } from '../types';

const extractMessage = (obj: any): string | undefined => {
  if (typeof obj === 'string') return obj;
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      return obj[key];
    }

    if (typeof obj[key] === 'object') {
      return extractMessage(obj[key]);
    }
    return undefined;
  }
};

type InitialState = {
  value: AlertState;
};
const initialState: InitialState = {
  value: {
    type: 'info',
    message: '',
  },
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    success: (_, action: PayloadAction<string>) => {
      return {
        value: {
          type: 'success',
          message: action.payload,
        },
      };
    },
    error: (_, action: PayloadAction<any>) => {
      const error = action.payload;
      let message = '';
      if (error.response?.data?.error) {
        message =
          extractMessage(error.response.data.error) ||
          'Something went wrong. Please try again later.';
      } else if (error.response?.data?.message) {
        message = error.response.data.message;
      } else if (error.message) {
        message = error.message;
      } else if (typeof error === 'string') {
        message = error;
      } else {
        message = 'Something went wrong. Please try again later.';
      }

      return {
        value: {
          type: 'error',
          message,
        },
      };
    },
    info: (_, action: PayloadAction<string>) => {
      return {
        value: {
          type: 'info',
          message: action.payload,
        },
      };
    },
    warning: (_, action: PayloadAction<string>) => {
      return {
        value: {
          type: 'warning',
          message: action.payload,
        },
      };
    },
    removeMessage: () => {
      return initialState;
    },
  },
});

export const { success, error, warning, info, removeMessage } =
  alertSlice.actions;
export default alertSlice.reducer;
