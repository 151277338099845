import React, { ErrorInfo } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    // Here you can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-screen h-screen flex flex-row justify-center items-center">
          <div>
            <h1 className="text-3xl font-bold text-center">
              Oops, Something went wrong! Please try again.
            </h1>
            <div
              className="bg-slate-100 mt-10 p-4 rounded-lg max-w-[800px] max-h-[70vh] overflow-y-auto"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {this.state.error?.toString()}
              <br />
              {this.state.errorInfo?.componentStack}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
