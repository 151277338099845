import React from 'react';
import { sendVerificationToEmail } from 'src/lib/api/auth';
import useAlert from 'src/hooks/useAlert';
import StyledButton from 'src/components/StyledButton';
import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const { successAlert, errorAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const parsedEmail = email ? decodeURIComponent(email) : '';

  const handleResendEmail = async () => {
    try {
      await sendVerificationToEmail(parsedEmail);
      successAlert('Email sent successfully!');
    } catch (e) {
      errorAlert(e);
    }
  };

  return (
    <>
      <p className="mt-3 text-center text-base font-normal text-grey-600">
        We have sent a verification code to <br />
        {parsedEmail}
      </p>
      <StyledButton
        onClick={() =>
          navigate(`/verify-otp?email=${encodeURIComponent(parsedEmail)}`)
        }
      >
        Verify email
      </StyledButton>
      <div className="mt-3 flex flex-row items-center justify-center text-center text-sm space-x-1 text-grey-600">
        <p className="font-normal">Didn’t receive the email?</p>{' '}
        <button
          className="flex flex-row items-center font-medium text-grey-600 border-0"
          onClick={handleResendEmail}
        >
          Click to resend
        </button>
      </div>
    </>
  );
};

export default VerifyEmail;
