import React from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationFormValidation } from './utils/validation';
import { RegisterForm } from './utils/types';
import {
  registerByEmailAndPassword,
  sendVerificationToEmail,
} from 'src/lib/api/auth';
import useAlert from 'src/hooks/useAlert';
import { RegisterBody } from 'src/lib/api/types';
import StyledButton from 'src/components/StyledButton';
import GoogleLogin from 'src/components/GoogleLogin';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'src/components/PhoneNumberInput';

const SignupForm = () => {
  const navigate = useNavigate();
  const { successAlert, errorAlert } = useAlert();
  const { control, handleSubmit } = useForm<RegisterForm>({
    defaultValues: {
      fullName: '',
      email: '',
      phoneNumber: '(+82)',
      password: '',
      retypedPassword: '',
    },
    resolver: yupResolver(RegistrationFormValidation),
  });

  const onSubmit = handleSubmit(async (form: RegisterForm) => {
    try {
      const registerBody: RegisterBody = {
        email: form.email,
        password: form.password,
        firstName: form.fullName.split(' ')[0],
        lastName: form.fullName.split(' ')[1],
        phoneNumber: form?.phoneNumber.replace(/\(|\)|\s/g, ''),
      };

      await registerByEmailAndPassword(registerBody);
      await sendVerificationToEmail(form.email);
      successAlert('Email sent successfully!');
      navigate(`/verify-email?email=${encodeURIComponent(form.email)}`);
    } catch (e) {
      errorAlert(e);
    }
  });

  return (
    <>
      <GoogleLogin />
      <div className="flex flex-row w-full items-center justify-between mt-5">
        <div className="border-t w-2/5 border-gray-200"></div>
        <span className="text-gray-400">or</span>
        <div className="bzzorder-t w-2/5 border-gray-200"></div>
      </div>
      <form onSubmit={onSubmit} className="space-y-4 mt-3">
        <Input
          type="text"
          label="Full name"
          name="fullName"
          placeholder="input your full name"
          control={control}
          required={true}
        />
        <Input
          type="email"
          label="Email"
          name="email"
          placeholder="input your email"
          control={control}
          required={true}
        />
        <PhoneInput
          type="tel"
          label="Phone number"
          name="phoneNumber"
          placeholder="input your phone number"
          control={control}
          required={true}
        />
        <Input
          type="password"
          label="Password"
          name="password"
          placeholder="input your password"
          control={control}
          required={true}
        />
        <Input
          type="password"
          label="Confirm your password"
          name="retypedPassword"
          placeholder="input your password"
          control={control}
          required={true}
        />
        <StyledButton type="submit">Get started</StyledButton>
      </form>
    </>
  );
};

export default SignupForm;
