import CounterInput from 'src/components/Counter';
import Input from 'src/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { IOrderForm } from './utils/types';
import { OrderFormValidation } from './utils/validation';
import StyledButton from 'src/components/StyledButton';
import { ICLoading } from 'src/lib/icons';
import React from 'react';
import { IEventInputField } from 'src/lib/types/event';

type Props = {
  price: number;
  currency: string;
  handleSubmit: (orderForm: IOrderForm) => Promise<any>;
  quantity: number;
  maxOrder: number;
  fields: IEventInputField[];
};

const OrderForm = ({ price, currency, handleSubmit, quantity, fields, maxOrder }: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const methods = useForm<any>({
    defaultValues: {
      quantity,
    },
  });

  const onSubmit = methods.handleSubmit(async (form: IOrderForm) => {
    if (loading)  return;
    setLoading(true);
    handleSubmit(form)
      .finally(() => setLoading(false));
  });

  return (
    <FormProvider {...methods}>
      <div className="flex justify-between items-center mb-6">
        <CounterInput
          control={methods.control}
          name="quantity"
          label="Quantity"
          maximum={maxOrder}
        />
        <p className="text-xl font-semibold text-second-400">
          {price === 0
            ? 'FREE'
            : `${(currency || '').toLocaleUpperCase()} ${price}`}
        </p>
      </div>
      {
        fields.map(field => (
          <div key={field.key} className="mb-4">
            <Input
              type="text"
              label={field.label}
              name={field.key}
              control={methods.control}
              placeholder={"Enter " + field.label}
              required={!!field.required}
            />
          </div>
        ))
      }
      <div className="text-sm	font-semibold	text-gray-600 mb-6">
        **To ensure seamless access to the venue, kindly join the group chat and
        introduce yourself briefly. Please note that due to limited space,
        failure to do so may affect your entry.
      </div>
      <StyledButton disabled={loading} onClick={onSubmit}>
        {loading && (
          <span className="mr-2">
            <ICLoading />
          </span>
        )}
        Submit
      </StyledButton>
    </FormProvider>
  );
};

export default OrderForm;
