'use client';

import { getMyProfile } from 'src/lib/api/auth';
import { IUser } from 'src/lib/types/user';
import { setUser, doneLoading } from 'src/redux/features/user-slice';
import { AppDispatch, useAppSelector } from 'src/redux/store';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useUser = (pUser?: null | IUser) => {
  const user = useAppSelector((state) => state.userReducer.user);
  const loading = useAppSelector((state) => state.userReducer.loading);
  const dispatch = useDispatch<AppDispatch>();

  const getUser = useCallback(async () => {
    try {
      const { data } = await getMyProfile();
      dispatch(setUser(data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(doneLoading());
    }
  }, [dispatch]);

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    pUser && dispatch(setUser(pUser));
  }, [pUser, dispatch])

  if (pUser) return { user: pUser };
  return { user, loading };
};
