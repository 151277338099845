import Tickets from './Tickets';
import React from 'react';
import { TicketStatus } from './utils/types';

const Content = () => {
  const [ticketStatus, setTicketStatus] = React.useState<TicketStatus>(
    TicketStatus.ALL,
  );
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    setPage(1);
  }, [ticketStatus]);

  return (
    <>
      <div className="w-full min-h-screen bg-grey-100 py-[100px] md:py-[160px] px-2.5 xl:px-32 lg:px-14">
        <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center px-3 gap-5">
          <h2 className="text-3xl font-bold">MY TICKET</h2>
          <ul className="flex flex-wrap text-sm rounded-lg font-base text-center text-gray-500 dark:text-gray-400 bg-primary-100 p-1.5">
            <li
              className={`mr-2 w-[90px] md:w-[110px] p-1.5 rounded-lg ${
                ticketStatus === TicketStatus.ALL
                  ? 'bg-white shadow-sm text-black'
                  : 'text-grey-600'
              } cursor-pointer`}
              onClick={() => setTicketStatus(TicketStatus.ALL)}
            >
              <span className="inline-block">All</span>
            </li>
            <li
              className={`mr-2  w-[90px] md:w-[110px] p-1.5 rounded-lg ${
                ticketStatus === TicketStatus.UP_COMING
                  ? 'bg-white shadow-sm text-black'
                  : 'text-grey-600'
              } cursor-pointer`}
              onClick={() => setTicketStatus(TicketStatus.UP_COMING)}
            >
              <span className="inline-block">Upcoming</span>
            </li>
            <li
              className={`mr-2  w-[90px] md:w-[110px] p-1.5 rounded-lg ${
                ticketStatus === TicketStatus.FINISHED
                  ? 'bg-white shadow-sm text-black'
                  : 'text-grey-600'
              } cursor-pointer`}
              onClick={() => setTicketStatus(TicketStatus.FINISHED)}
            >
              <span className="inline-block">Finished</span>
            </li>
          </ul>
        </div>
        <div className="w-full mt-7">
          <Tickets ticketStatus={ticketStatus} page={page} />
        </div>

        <div className="w-full flex justify-end mt-10">
          <span
            className="flex items-center justify-center cursor-pointer px-3 h-8 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => setPage(page > 1 ? page - 1 : page)}
          >
            <svg
              className="w-3.5 h-3.5 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 5H1m0 0 4 4M1 5l4-4"
              />
            </svg>
            Previous
          </span>
          <span
            className="flex items-center justify-center  cursor-pointer px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => setPage(page + 1)}
          >
            Next
            <svg
              className="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default Content;
