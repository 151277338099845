import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<any>;
  type?: string;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
};
const Input = ({
  control,
  label,
  name,
  placeholder = '',
  type = 'text',
  required = false,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: `${label} is required` }
      }}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <div>
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-grey-600"
          >
            {label} {required && <span className="text-grey-600">*</span>}
          </label>
          <div className="my-1">
            <input
              type={type}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 py-1"
            />
            {errors && errors[name] && (
              <span className="font-sans text-red-500 text-xs leading-3 mt-2">
                {errors[name]?.message as string}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default Input;
