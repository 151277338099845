import React from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordFormValidation } from './utils/validation';
import { resetPassword } from 'src/lib/api/auth';
import useAlert from 'src/hooks/useAlert';
import { IResetPasswordForm } from './utils/types';
import StyledButton from 'src/components/StyledButton';
import { ResetPasswordBody } from 'src/lib/api/types';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { successAlert, errorAlert } = useAlert();
  const { control, handleSubmit } = useForm<IResetPasswordForm>({
    resolver: yupResolver(ResetPasswordFormValidation),
  });

  const onSubmit = handleSubmit(async (form: IResetPasswordForm) => {
    try {
      const resetPasswordBody: ResetPasswordBody = {
        email: form.email,
        currentPassword: form.currentPassword,
        newPassword: form.newPassword,
      };

      await resetPassword(resetPasswordBody);
      successAlert('Reset password successfully!');
      navigate('/sign-in');
    } catch (e) {
      errorAlert(e);
    }
  });

  return (
    <>
      <form onSubmit={onSubmit} className="space-y-4">
        <Input
          type="email"
          label="Email"
          name="email"
          control={control}
          required
        />
        <Input
          type="password"
          label="Current password"
          name="currentPassword"
          control={control}
          required
        />
        <Input
          type="password"
          label="New password"
          name="newPassword"
          control={control}
          required
        />
        <Input
          type="password"
          label="Confirm your new password"
          name="retypedPassword"
          control={control}
          required
        />
        <StyledButton type="submit">Submit</StyledButton>
      </form>
    </>
  );
};

export default ResetPasswordForm;
