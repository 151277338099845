import { useNavigate } from 'react-router-dom';
import SignupForm from './components/SignUpForm';
import { LogoDark } from 'src/components/LogoDark';


export default function Register() {

  const navigate = useNavigate();
  return (
    <div className="container mx-auto w-screen min-h-screen">
      <div className="flex flex-row w-full h-screen justify-center mt-10 lg:mt-20 mb-20 lg:mb-0">
        <div className="w-80 lg:w-96">
          <button onClick={() => navigate('/')}>
            <img
              src="LogoDark.png"
              alt="logo"
              className="h-[40px] md:h-[50px] w-[180px] md:w-[220px]"
            />
          </button>
          <h2 className="mt-6 text-2xl font-semibold leading-9 tracking-tight text-gray-900">
            Sign up
          </h2>
          <p className="mt-2.5 text-base text-grey-600 font-normal mb-6">
            Welcome to our platform! Sign up now to start your personalized
            journey with us.
          </p>
          <SignupForm />

          {/* Terms and conditions agreement */}
          <div className="text-center mt-4">
            By signing up, we assume you agree to our{' '}
            <span
              className="text-blue-600 cursor-pointer hover:underline"
              onClick={() => navigate('/terms-and-conditions')}
            >
              terms and use
            </span>.
          </div>

          <p className="mt-5 text-center text-sm text-grey-600 font-normal">
            Already have an account?{' '}
            <button onClick={() => navigate('/sign-in')}>Login now!</button>
          </p>
        </div>
      </div>
    </div>
  );
}
