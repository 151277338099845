import ICKey from 'src/lib/icons/ICKey';
import ResetPasswordForm from './components/ResetPassword';

export default function ResetPassword() {
  return (
    <div className="container mx-auto w-screen min-h-screen">
      <div className="flex flex-row w-full h-screen justify-center mt-20">
        <div className="w-[22rem] lg:w-96">
          <div className="flex flex-column items-center justify-center">
            <ICKey />
          </div>
          <h2 className="my-6 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
            Reset password
          </h2>
          <ResetPasswordForm />
        </div>
      </div>
    </div>
  );
}
