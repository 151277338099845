import { getEvents } from 'src/lib/api/events';
import { getPicture } from 'src/lib/api/pics';
import { IEvent, PromotionUnit } from 'src/lib/types/event';
import { formatDate } from 'src/lib/utils/date';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getCityCountry from 'src/lib/api/googleMap';
import { getImageURL } from 'src/lib/utils/image';
import { TicketStatus } from './utils/types';
import { getMyOrders } from 'src/lib/api/order';
import { IOrder } from 'src/lib/types/order';
import { ISearchOrderQuery } from 'src/lib/api/types';
import useAlert from 'src/hooks/useAlert';
import { ICLoading } from 'src/lib/icons';
type IOrderData = IOrder & {
  bannerUrl: string;
};

const Ticket = ({
  order: {
    id,
    metadata: { eventInfo },
    items,
    totalAmount,
    bannerUrl,
  },
}: {
  order: IOrderData;
}) => {
  const navigate = useNavigate();
  const option = items[0]?.productOptionInfo || null;
  const currency = option ? option.currency : 'USD';
  const time = option?.options[1]?.label || '';
  const isFinished = new Date(time).getTime() < new Date().getTime() || false;

  return (
    <div
      className="rounded-xl border cursor-pointer relative"
      onClick={() => navigate(`/my-tickets/${id}`)}
    >
      <span
        className={`absolute right-2 top-2 px-3 py-1 ${
          isFinished ? 'bg-red-100 text-red-300' : 'bg-blue-100 text-blue-400'
        } rounded-full px-2 py-1 text-base font-semibold`}
      >
        {isFinished ? 'Finished' : 'Upcoming'}
      </span>
      <img className="rounded-t-xl" src={bannerUrl || ''} />
      <div className="p-4">
        <div className="text-black text-lg font-bold line-clamp-2 mb-2">
          {eventInfo?.name || ''}
        </div>
        <div className="text-gray-600 text-sm font-normal mb-2">
          {eventInfo?.location || ''}
        </div>
        <div className="text-primary-500 text-sm font-normal mb-2">
          {formatDate(option?.options[1]?.label, option?.options[1]?.timeZone)}
        </div>
        <div className="text-second-400 text-md font-semibold mb-2">
          {totalAmount === 0
            ? 'FREE'
            : `${(currency || '').toLocaleUpperCase()} ${
                totalAmount || 0
              }`}{' '}
        </div>
      </div>
    </div>
  );
};

const Tickets: React.FC<{ ticketStatus: TicketStatus; page: number }> = ({
  ticketStatus,
  page,
}) => {
  const [orders, setOrders] = useState<IOrderData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { errorAlert } = useAlert();

  useEffect(() => {
    const searchQuery: ISearchOrderQuery = {
      type: 'ticket',
      page: page.toString(),
    };
    if (ticketStatus === TicketStatus.UP_COMING) {
      searchQuery['from'] = new Date().toISOString();
    } else if (ticketStatus === TicketStatus.FINISHED) {
      searchQuery['to'] = new Date().toISOString();
    }

    setLoading(true);
    getMyOrders(searchQuery)
      .then((data) => {
        if (data?.data && Array.isArray(data.data) && data.data.length > 0) {
          const ordersWithImages = data.data.map((order: IOrder) => ({
            ...order,
            bannerUrl: getImageURL(order.metadata.eventInfo.banner) || '',
          }));
          setOrders(ordersWithImages);
        } else if (data?.data && Array.isArray(data.data) && data.data.length === 0) {
          setOrders([]);
        }
      })
      .catch((e) => {
        errorAlert(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ticketStatus, page]);

  if (loading) {
    return (
      <div
        className="flex flex-row justify-center items-center bg-gray-100"
        style={{ minHeight: '90vh', padding: '159px' }}
      >
        <ICLoading w={10} h={10} />
      </div>
    );
  }

  return (
    <div className="px-2.5 min-h-[500px]">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {orders.map((order) => (
          <Ticket key={order.id} order={order} />
        ))}
      </div>
    </div>
  );
};

export default Tickets;
