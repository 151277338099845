const ICQRCode = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 9.33333H2V2H9.33333V9.33333ZM3.33333 8H8V3.33333H3.33333V8Z"
        fill="#181818"
      />
      <path
        d="M18.0003 9.33333H10.667V2H18.0003V9.33333ZM12.0003 8H16.667V3.33333H12.0003V8Z"
        fill="#181818"
      />
      <path
        d="M9.33333 18.0003H2V10.667H9.33333V18.0003ZM3.33333 16.667H8V12.0003H3.33333V16.667Z"
        fill="#181818"
      />
      <path
        d="M17.9997 15.3337H16.6663V12.0003H15.333V14.0003H11.333V10.667H12.6663V12.667H13.9997V10.667H17.9997V15.3337Z"
        fill="#181818"
      />
      <path
        d="M17.9997 17.9997H11.333V15.333H12.6663V16.6663H17.9997V17.9997Z"
        fill="#181818"
      />
      <path
        d="M6.66699 4.66699H4.66699V6.66699H6.66699V4.66699Z"
        fill="#181818"
      />
      <path d="M15.333 4.66699H13.333V6.66699H15.333V4.66699Z" fill="#181818" />
      <path d="M6.66699 13.333H4.66699V15.333H6.66699V13.333Z" fill="#181818" />
      <rect width="20" height="20" stroke="#0C0C4F" />
    </svg>
  );
};

export default ICQRCode;
