import { ICMinus, ICPlus } from 'src/lib/icons';
import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<any>;
  type?: string;
  label: string;
  name: string;
  maximum: number;
};
const CounterInput = ({ control, label, name, maximum }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const disablePlus = maximum ? (value >= maximum) : false;

        return (
          <div>
            <label
              htmlFor={name}
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {label}
            </label>
            <div className="flex flex-row h-10 rounded-lg relative bg-transparent my-2">
              <button
                className="text-gray-600 border-2 border-slate-400 rounded cursor-pointer w-10 h-10 flex items-center justify-center"
                onClick={() => {
                  if (value > 1) onChange(value - 1);
                }}
              >
                <ICMinus />
              </button>
              <p className="w-14 text-center font-semibold text-gray-700 pt-2">
                {value}
              </p>
              <button
                className={`text-gray-600 border-2 border-slate-400 rounded w-10 h-10 flex items-center justify-center
                ${disablePlus ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                disabled={disablePlus}
                onClick={() => onChange(value + 1)}
              >
                <ICPlus />
              </button>
            </div>
            {errors && errors[name] && (
              <span className="font-sans text-red-500 text-xs leading-3 mt-2">
                {errors[name]?.message as string}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default CounterInput;
