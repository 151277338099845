import React from 'react';
import { Logo } from './Logo';
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      id="tn-footer"
      className="bg-primary-500 px-2.5 xl:px-32 lg:px-14 py-6 lg:py-16"
    >
      <div className="flex gap-2 items-center justify-center mb-5">
        <button onClick={() => navigate('/')}>
          <img
            src={'/LogoBright.png'}
            alt="logo"
            className="mt-2 w-[150px] h-[30px] md:w-[180px] md:h-[40px]"
          />
        </button>
      </div>
      <div className="border-t mb-8 border-slate-100" />
      <div className="flex justify-between">
        <span className="text-primary-100 text-xs lg:text-sm">
          © 2023 StarkPass. All rights reserved.
        </span>
        <div className="flex gap-4">
          <p className="text-primary-100 text-xs lg:text-sm cursor-pointer">
          <span
              onClick={() => navigate('/terms-and-conditions')}
            > Terms & Use </span>
          </p>
          {/* <p className="text-primary-100 text-xs lg:text-sm cursor-pointer">
            Privacy
          </p>
          <p className="text-primary-100 text-xs lg:text-sm cursor-pointer">
            Cookies
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
