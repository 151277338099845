type Props = {
  modalLabel?: string;
  onClose?: () => void;
  Footer?: React.ReactNode;
  children: React.ReactNode;
};

const Modal: React.FC<Props> = ({
  modalLabel = '',
  onClose,
  Footer,
  children,
}) => {
  return (
    <div
      className="fixed top-0 left-0 right-0 z-50 p-4 w-full h-full flex items-center justify-center"
      style={{ backgroundColor: 'rgba(0,0,0,.7)' }}
    >
      <div className="relative bg-white w-full z-60 max-w-2xl max-h-full rounded-3xl overflow-hidden" style={{ maxHeight: '900' }}>
        {/* Modal content */}
        <div className="flex flex-col h-full">
          {/* Modal header */}
          {(modalLabel || !!onClose) && (
            <div className="flex items-start justify-between p-4 dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {modalLabel}
              </h3>
              {!!onClose && (
                <button
                  type="button"
                  onClick={onClose}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              )}
            </div>
          )}
          {/* Scrollable Modal body */}
          <div className="flex-1 overflow-auto p-4">
            {children}
          </div>
          {/* Modal footer */}
          {Footer && (
            <div>
              {Footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
