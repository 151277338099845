import {
  success,
  error,
  warning,
  info,
  removeMessage,
} from 'src/redux/features/alert-slice';
import { AppDispatch } from 'src/redux/store';
import { useDispatch } from 'react-redux';

const useAlert = () => {
  const dispatch = useDispatch<AppDispatch>();
  const successAlert = (message: string) => dispatch(success(message));
  const errorAlert = (errorObj: any) => dispatch(error(errorObj));
  const warningAlert = (message: string) => dispatch(warning(message));
  const infoAlert = (message: string) => dispatch(info(message));
  const removeAlert = () => dispatch(removeMessage());
  return { successAlert, errorAlert, warningAlert, infoAlert, removeAlert };
};

export default useAlert;
