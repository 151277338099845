import ICKey from 'src/lib/icons/ICKey';
import ForgotPasswordForm from './components/ForgotPasswordForm';

export default function ForgotPassword() {
  return (
    <div className="container mx-auto w-screen min-h-screen">
      <div className="flex flex-row w-full h-screen justify-center mt-20">
        <div className="w-[22rem] lg:w-96">
          <div className="flex flex-column items-center justify-center">
            <ICKey />
          </div>
          <h2 className="mt-6 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
            Forgot password?
          </h2>
          <p className="mt-2.5 text-center text-base text-grey-600 font-normal mb-6">
            No worries, we’ll send you reset instructions.
          </p>
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
}
