import axios from 'axios';
import Cookies from 'js-cookie';
import { removeTokens, setTokens } from './utils/auth';
import { baseURL } from './baseURL';

async function refreshAccessToken(refreshToken: string) {
  return await axios.post(`${baseURL}/auth/refresh-token`, {
    refreshToken,
  });
}

const baseApi = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
baseApi.interceptors.request.use((config) => {
  const accessToken = Cookies.get('access_token');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

// Response interceptor
baseApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get('refresh_token');

      if (!refreshToken) {
        throw error;
      }

      return refreshAccessToken(refreshToken)
        .then(({ data }) => {
          const { accessToken, refreshToken } = data;
          setTokens(accessToken, refreshToken);
          baseApi.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${accessToken}`;
        })
        .then(() => {
          return baseApi(originalRequest);
        })
        .catch((err) => {
          removeTokens();
          throw error;
        });
    }
    throw error;
  },
);

export default baseApi;
