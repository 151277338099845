'use client';

import React from 'react';
import { ISpeaker, ISponsor } from 'src/lib/types/event';
import { getImageURL } from 'src/lib/utils/image';

const Member = ({ sponsor }: { sponsor: ISponsor }) => (
  <div className="max-w-[16.67%] text-center">
    {sponsor?.avatar ? (
      <img
        className="h-auto mx-auto mb-5"
        src={getImageURL(sponsor.avatar || '')}
      />
    ) : (
      <div className="rounded-full w-30 h-30 mx-auto mb-5 bg-gray-100 text-3xl flex flex-row justify-center items-center text-primary-400">
        {sponsor?.name?.[0] || ''}
      </div>
    )}
  </div>
);

const Members = ({ sponsors }: { sponsors: ISponsor[] }) => {
  return (
    <div
      className="w-full flex gap-4 justify-center items-center"
    >
      {sponsors.map((item, index) => (
        <Member key={index} sponsor={item} />
      ))}
    </div>
  );
};

type Props = {
  sponsors: ISponsor[];
};
const Sponsors: React.FC<Props> = ({ sponsors }) => {
  const platSponsors = sponsors.filter((sponsor) => sponsor?.name === 'platinum');
  const goldSponsors = sponsors.filter((sponsor) => sponsor?.name === 'gold');
  const silverSponsors = sponsors.filter(
    (sponsor) => sponsor?.name === 'silver',
  );
  const bronzeSponsors = sponsors.filter(
    (sponsor) => sponsor?.name === 'bronze',
  );
  const otherSponsors = sponsors.filter((sponsor) => sponsor?.name === '');
  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 flex-1 mt-8">
      <div className="text-black text-3xl text-center	font-bold mb-5">
        Sponsors
      </div>
      {platSponsors && (
        <div className="w-full flex flex-col items-center gap-5">
          <p className="text-primary-600 font-semibold text-lg">Platinum</p>
          <Members sponsors={platSponsors} />
        </div>
      )}
      {goldSponsors && (
        <div className="w-full flex flex-col items-center gap-5">
          {/* <img src="/GoldSponsor.svg" alt="Gold Sponsor" /> */}
          <p className="text-primary-600 font-semibold text-lg">Gold</p>
          <Members sponsors={goldSponsors} />
        </div>
      )}
      {silverSponsors && (
        <div className="w-full flex flex-col items-center gap-5 mt-10">
          {/* <img src="/SilverSponsor.svg" alt="Silver Sponsor" /> */}
          <p className="text-primary-600 font-semibold text-lg">Silver</p>
          <Members sponsors={silverSponsors} />
        </div>
      )}
      {bronzeSponsors && (
        <div className="w-full flex flex-col items-center gap-5 mt-10">
          {/* <img src="/BronzeSponsor.svg" alt="Bronze Sponsor" /> */}
          <p className="text-primary-600 font-semibold text-lg">Bronze</p>
          <Members sponsors={bronzeSponsors} />
        </div>
      )}
      {bronzeSponsors && (
        <div className="w-full flex flex-col items-center gap-5 mt-10">
          <Members sponsors={otherSponsors} />
        </div>
      )}
    </div>
  );
};

export default Sponsors;
