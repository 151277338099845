const StyledButton: React.FC<{
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ type, onClick, children, disabled }) => {
  return (
    <button
      type={type}
      className="mt-5 flex w-full justify-center rounded-md bg-primary-600 px-5 py-3 text-base font-normal leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      onClick={onClick}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
};

export default StyledButton;
