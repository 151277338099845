import * as yup from 'yup';

export const ResetPasswordFormValidation = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must have at least 8 characters containing 1 uppercase, 1 lowercase, 1 number and 1 special character',
    )
    .required('New password is required'),
  retypedPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Retyped password is required'),
  email: yup.string().email().required('Email is required'),
});
