const ICCheck = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http:/www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_110_32887)">
        <path
          d="M20.5 0C9.455 0 0.5 8.955 0.5 20C0.5 31.045 9.455 40 20.5 40C31.545 40 40.5 31.045 40.5 20C40.4683 8.96833 31.5317 0.0316667 20.5 0ZM32.5117 12.845L17.5117 27.845C17.1867 28.17 16.76 28.3333 16.3333 28.3333C15.9067 28.3333 15.48 28.17 15.155 27.845L8.48833 21.1783C7.83667 20.5267 7.83667 19.4733 8.48833 18.8217C9.14 18.17 10.1933 18.17 10.845 18.8217L16.3333 24.31L30.155 10.4883C30.8067 9.83667 31.86 9.83667 32.5117 10.4883C33.1633 11.14 33.1633 12.1933 32.5117 12.845Z"
          fill="#17B26A"
        />
      </g>
      <defs>
        <clipPath id="clip0_110_32887">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ICCheck;
