const ICTimeSpot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <circle cx="11" cy="11" r="4" fill="#181818" />
      <rect
        x="0.75"
        y="0.75"
        width="20.5"
        height="20.5"
        rx="10.25"
        stroke="#181818"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ICTimeSpot;
