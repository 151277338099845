import ImageCarousel from 'src/components/Carousel';
import useAlert from 'src/hooks/useAlert';
import { useUser } from 'src/hooks/useUser';
import { getEvent } from 'src/lib/api/events';
import { IEvent } from 'src/lib/types/event';
import { formatDate } from 'src/lib/utils/date';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  CalendarIcon,
  LocationIcon,
  TicketIcon,
} from '../../../components/event/EventIcons';
import { ICLoading } from 'src/lib/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getImageURL } from 'src/lib/utils/image';
import Speakers from '../../../components/event/Speakers';
import Schedules from '../../../components/event/Schedules';
import { EventData } from '../../../components/event/types';
import GoogleMap from '../../../components/event/GoogleMap';
import About from '../../../components/event/About';
import Sponsors from 'src/components/event/Sponsors';

const EventDetail = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const { user } = useUser();
  const { errorAlert } = useAlert();
  const [event, setEvent] = useState<EventData>();
  const eventId = params.id as string;
  const methods = useForm<{
    quantity: number;
  }>({
    defaultValues: {
      quantity: 1,
    },
    resolver: yupResolver(
      yup.object().shape({
        quantity: yup.number().min(1).required(),
      }),
    ),
  });

  // MAPPED DATA
  const performanceDetail = event?.information?.performanceDetail;
  const speakers = performanceDetail?.speakers;
  const schedules = performanceDetail?.schedules;
  const sponsors = performanceDetail?.sponsors;

  useEffect(() => {
    if (eventId) {
      getEvent(eventId)
        .then((event: IEvent) => {
          if (event?.photos) {
            const photos = event.photos.map((photo) => getImageURL(photo.key));
            const photoUrls = photos || [];
            setEvent({ ...event, imageUrls: photoUrls });
          }
        })
        .catch((e) => {
          errorAlert(e);
        });
    }
  }, [eventId]);

  const handleGetTicket = () => {
    if (user)
      navigate(
        `/purchase-ticket/${event?.id}?quantity=${methods.getValues(
          'quantity',
        )}`,
      );
    else {
      navigate(`/sign-in?returnURL=${encodeURIComponent(pathname)}`);
      errorAlert('Please sign in first!');
    }
  };

  if (!event) {
    return (
      <div
        className="flex flex-row justify-center items-center bg-gray-100"
        style={{ minHeight: '90vh', padding: '159px' }}
      >
        <ICLoading w={10} h={10} />
      </div>
    );
  }
  return (
    <div className="bg-gray-100 px-2.5 xl:px-32 lg:px-14 pt-20 md:pt-32 min-h-screen pb-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 rounded-3xl bg-primary-500 p-6 lg:p-10 xl:p-16 mb-8 flex gap-8 w-full">
        {event.imageUrls && event.imageUrls.length > 0 && (
          <div className="rounded-2xl">
            <ImageCarousel imageUrls={event.imageUrls} />
          </div>
        )}
        <div className="text-white flex-1">
          <div className="text-xl md:text-4xl font-semibold mb-4">
            {event?.name}
          </div>
          <div className="font-normal	text-base md:text-lg flex items-center gap-4 mb-4">
            <div className="bg-primary-100 p-1 rounded">
              <LocationIcon />
            </div>
            {event?.location || "TBA"}
          </div>
          <div className="font-normal	text-base md:text-lg flex items-center gap-4 mb-5">
            <div className="bg-primary-100 p-1 rounded">
              <CalendarIcon />
            </div>
            {event?.product &&
              formatDate(
                event.product.options[0].options[1].label!,
                event.product?.options[0].options[1].timeZone,
              )}
          </div>
          <div className="block md:flex gap-7 items-center">
            {/* <TicketInput control={methods.control} name="quantity" /> */}
            <button
              className="px-5 md:px-2 xl:px-5 py-3 md:py-2 xl:py-3 bg-white rounded-lg flex gap-2 justify-center items-center mt-7 md:mt-0"
              onClick={handleGetTicket}
            >
              <TicketIcon />
              <div className="text-primary-600 font-semibold text-base md:text-xs xl:text-base">
                Get ticket
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <About event={event} />
        <GoogleMap event={event} />
      </div>
      {speakers && speakers.length > 0 && <Speakers speakers={speakers} />}
      {schedules && schedules.length > 0 && <Schedules schedules={schedules} />}
      {sponsors && sponsors.length > 0 && <Sponsors sponsors={sponsors} />}
    </div>
  );
};

export default EventDetail;
