import React from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  // @eslint-disable-next-line
  control: Control<any>;
  name: string;
  moveNext: () => void;
};

const OtpInput = React.forwardRef<HTMLInputElement, Props>(
  ({ control, name, moveNext }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <div className="w-14 lg:w-16 w-14 lg:h-16 ">
              <input
                ref={ref}
                className="w-full h-full flex flex-col items-center justify-center text-center text-primary-600 px-3 outline-none rounded-lg border border-primary-500 text-4xl bg-white focus:bg-gray-100 focus:ring-1 ring-primary-600"
                type="text"
                name={name}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  moveNext();
                }}
              />
            </div>
          </>
        )}
      />
    );
  },
);

OtpInput.displayName = 'OtpInput';

export default OtpInput;
