import './globals.css';
import ReduxProvider from './redux/provider';
import Alert from 'src/components/Alert';
import { Suspense } from 'react';
import Loading from './pages/loading';
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';
import Home from './pages/home';
import Register from './pages/register';
import SignIn from './pages/sign-in';
import VerifyEmail from './pages/verify-email';
import VerifyOtp from './pages/verify-otp';
import Verified from './pages/verified';
import ResetPassword from './pages/reset-password';
import ForgotPassword from './pages/forgot-password';
import Event from './pages/event';
import PurchaseTicketPage from './pages/purchase-ticket';
import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import MyTicketPage from './pages/my-ticket';
import Terms from './pages/terms';
import OrderDetailPage from './pages/order-details';
import Protected from './components/Protected';
import AllEvents from './pages/events';

import ScrollToTop from './components/ScrollToTop';
import LinkPreview from './components/LinkPreview';

const router = createBrowserRouter([{ path: '*', Component: Root }]);

function Root() {
  return (
    <ErrorBoundary>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="my-tickets"
          element={
            <Layout>
              <Protected>
                <MyTicketPage />
              </Protected>
            </Layout>
          }
        />
        <Route path="register" element={<Register />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="verify-otp" element={<VerifyOtp />} />
        <Route path="verified" element={<Verified />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="terms-and-conditions" element={<Layout><Terms /></Layout>} />
        <Route
          path="events/:id"
          element={
            <Layout>
              <Event />
            </Layout>
          }
        />
        <Route
          path="events"
          element={
            <Layout>
              <AllEvents />
            </Layout>
          }
        />
        <Route
          path="purchase-ticket/:id"
          element={
            <Layout>
              <Protected>
                <PurchaseTicketPage />
              </Protected>
            </Layout>
          }
        />
        <Route
          path="my-tickets/:id"
          element={
            <Layout>
              <Protected>
                <OrderDetailPage />
              </Protected>
            </Layout>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
}

export default function App() {
  return (
    <ReduxProvider>
      <Alert />
      <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </Suspense>
    </ReduxProvider>
  );
}
