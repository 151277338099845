/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalendarIcon, LocationIcon } from 'src/components/event/EventIcons';
import Modal from 'src/components/Modal';
import useAlert from 'src/hooks/useAlert';
import { useUser } from 'src/hooks/useUser';
import { getEvent } from 'src/lib/api/events';
import { createOrder } from 'src/lib/api/order';
import { ICCalendar, ICCheck, ICLocation } from 'src/lib/icons';
import { IEvent, PromotionUnit } from 'src/lib/types/event';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import OrderForm from './OrderForm';
import { IOrderForm } from './utils/types';
import StyledButton from 'src/components/StyledButton';
import { formatDate } from 'src/lib/utils/date';
import ImageCarousel from 'src/components/Carousel';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { getImageURL } from 'src/lib/utils/image';
import { IOrderFormBody } from 'src/lib/api/types';

type EventData = IEvent & {
  imageUrls: string[];
};

const Content = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const [orderForm, setOrderForm] = React.useState<IOrderForm | null>(null);
  const [openBuyTicketResultModal, setOpenBuyTicketResultModal] =
    useState<boolean>(false);
  const { errorAlert } = useAlert();
  const [event, setEvent] = useState<EventData>();

  /*MAPPED DATA*/
  const eventId = params.id as string;
  const quantity = searchParams.get('quantity')
    ? Number(searchParams.get('quantity'))
    : 1;
  const currency = event?.product?.options
    ? event?.product?.options[0].currency
    : 'USD';

  /*FUNCTIONS*/
  const retrievePrice = () => {
    const price = event?.product?.options
      ? event?.product?.options[0].price
      : 0;
    const promotionValue =
      event?.eventPromotion && event.eventPromotion.length > 0
        ? event?.eventPromotion[0].promotion.value
        : 0;
    const promotionUnit =
      event?.eventPromotion && event.eventPromotion.length > 0
        ? event?.eventPromotion[0].promotion.unit
        : 'number';
    if (promotionUnit === PromotionUnit.PERCENT) {
      return price - (price * promotionValue) / 100;
    }
    return price - promotionValue;
  };

  const handleSubmit = async (form: IOrderForm) => {
    if (!Cookies.get('access_token')) {
      navigate(`/sign-in?returnURL=${encodeURIComponent(pathname)}`);
      errorAlert('Please login to continue');
      return;
    }

    const product = event?.product;
    const productOption = product?.options?.[0];

    if (!product || !productOption) {
      return errorAlert('Product data is not found');
    }

    try {
      const metadata: any = {
        ticketBuyer: {
          name: `${user?.profile?.lastName} ${user?.profile?.firstName}`,
        },
      };
      event?.metadata?.buyerInputs?.forEach(({ key }) => {
        if (form[key]) {
          metadata.ticketBuyer[key] = form[key];
        }
      });
      
      await createOrder({
        type: product.type,
        payment: {
          provider: 'payple',
        },
        items: [
          {
            productId: product.id,
            productOptionId: productOption.id,
            quantity: form.quantity,
          },
        ],
        metadata,
      } as IOrderFormBody);
      setOpenBuyTicketResultModal(true);
      setOrderForm(form);
    } catch (err) {
      errorAlert(err);
    }
  };

  const displayInfo = (fontSize: 'sm' | 'md', event: IEvent) => (
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <ICLocation />
        <p className={`text-${fontSize} ml-2`}>{event?.location || ''}</p>
      </div>
      <div className="flex items-center">
        <ICCalendar />
        <p className={`text-${fontSize} ml-2`}>
          {formatDate(
            event.product.options[0].options[1].label!,
            event.product?.options[0].options[1].timeZone,
          )}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    if (eventId)
      getEvent(eventId)
        .then((event: IEvent) => {
          const photos = event?.photos?.map((photo) => getImageURL(photo.key));
          const photoUrls = photos || [];
          setEvent({ ...event, imageUrls: photoUrls });
        })
        .catch((e) => {
          errorAlert(e);
        });
  }, [eventId]);

  return (
    <>
      {openBuyTicketResultModal && !!orderForm && (
        <Modal>
          <div className="px-5 lg:px-20 py-10 lg:py-10">
            <div className="flex flex-col items-center gap-5">
              <ICCheck />
              <p className="text-xl">Thank for your order!</p>
            </div>
            {event?.requireAdminApproval ? (
              <div className="text-md my-4">
                Your registration is currently pending approval
              </div>
            ) : (
              <p className="text-md my-4">
                We sent{' '}
                <span className="font-bold">
                  {orderForm?.quantity} ticket
                  {orderForm && orderForm?.quantity > 1 && 's'}
                </span>{' '}
                to your email <span className="font-bold">{user?.email}</span>
              </p>
            )}
            <div className="text-lg font-semibold my-4">{event?.name}</div>
            <div className="font-normal	text-lg flex items-center gap-4 mb-4">
              <div className="bg-primary-100 p-1 rounded">
                <LocationIcon />
              </div>
              {event?.location}
            </div>
            <div className="font-normal	text-lg flex items-center gap-4 mb-4">
              <div className="bg-primary-100 p-1 rounded">
                <CalendarIcon />
              </div>
              {event?.product &&
                formatDate(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  event.product.options[0].options[1].label!,
                  event.product?.options[0].options[1].timeZone,
                )}
            </div>
            <StyledButton
              onClick={() => {
                setOpenBuyTicketResultModal(false);
                navigate(`/my-tickets`);
              }}
            >
              {event?.requireAdminApproval ? 'OK' : 'Go to My Tickets'}
            </StyledButton>
          </div>
        </Modal>
      )}
      <div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full min-h-screen px-0 py-0 flex justify-center"
        style={{ marginTop: '80px' }}
      >
        <div className="w-full h-1000 pl-2.5 lg:pl-14 xl:pl-32 pr-2.5 xl:pr-16 py-2.5 md:py-16">
          <p className="text-base text-gray-400 mb-4">You're going to</p>
          <p className="text-2xl font-semibold mb-4">{event?.product?.title}</p>
          {event && event.imageUrls && event.imageUrls.length > 0 && (
            <div className="w-full rounded-2xl my-8">
              <ImageCarousel imageUrls={event.imageUrls} />
            </div>
          )}
          {event && event.product && displayInfo('md', event)}
        </div>
        <div className="w-full bg-slate-50 h-1000 pr-2.5 lg:pr-14 xl:pr-32 pl-2.5 sm:pl-5 lg:pl-10 xl:pl-16 py-16">
          <OrderForm
            handleSubmit={handleSubmit}
            price={retrievePrice()}
            currency={currency}
            quantity={quantity}
            maxOrder={event?.product.maxOrder || event?.product.quantity || 0}
            fields={event?.metadata?.buyerInputs || []}
          />
        </div>
      </div>
    </>
  );
};

export default Content;
