import useAlert from 'src/hooks/useAlert';
import { loginWithOauth } from 'src/lib/api/auth';
import { OauthLoginForm } from 'src/lib/api/types';
import { auth, googleProvider } from 'src/lib/firebase/config';
import { ICGoogle, ICLoading } from 'src/lib/icons';
import { setTokens } from 'src/lib/utils/auth';
import { UserCredential, signInWithPopup } from 'firebase/auth';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type OauthUser = UserCredential['user'] & {
  accessToken: string;
};
const GoogleLogin = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { successAlert, errorAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const returnURL = searchParams.get('returnURL');

  const onSubmit = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);

      if (!result?.user || !(result.user as OauthUser).accessToken) return;
      const oauthLoginForm: OauthLoginForm = {
        token: (result.user as OauthUser).accessToken,
        provider: 'firebase',
      };

      const { data } = await loginWithOauth(oauthLoginForm);

      if (!data) return;
      if (typeof window === 'undefined') return;
      setTokens(data.accessToken, data.refreshToken);
      successAlert('Logged In successfully!');

      navigate(returnURL ? decodeURIComponent(returnURL) : '/');
    } catch (e) {
      errorAlert(e);
    }
    setLoading(false);
  };

  return (
    <button
      className="flex flex-row justify-center w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-white hover:bg-slate-50 focus:bg-slate-50 sm:text-sm sm:leading-6 px-2 py-1 transition-all"
      onClick={onSubmit}
    >
      <span className="flex flex-row items-center gap-3 text-base font-semibold py-1">
        {loading ? <ICLoading /> : <ICGoogle />} Continue with Google
      </span>
    </button>
  );
};

export default GoogleLogin;
