import React from 'react';

type DropdownItem = {
  label: string;
  onClick: () => void;
};

type Props = {
  button: React.ReactElement;
  list: DropdownItem[];
  className?: string;
  open: boolean;
};

const DropdownMenu: React.FC<Props> = ({ button, list, className, open }) => {
  return (
    <div className={`relative inline-block text-left ${className}`}>
      <div>{React.cloneElement(button, { ...button.props })}</div>
      {open && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {list.map((item, index) => (
              <button
                key={index}
                className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                role="menuitem"
                onClick={item.onClick}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
