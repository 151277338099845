import { useUser } from 'src/hooks/useUser';
import { IUser } from 'src/lib/types/user';
import { removeUser } from 'src/redux/features/user-slice';
import { AppDispatch } from 'src/redux/store';
import React from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu from './DropdownMenu';
import Logo from 'src/lib/assets/Logo.svg';
import { ICArrowDown } from 'src/lib/icons';
import { getPicture } from 'src/lib/api/pics';
import { useNavigate } from 'react-router-dom';

const NavBar: React.FC<{ user: null | IUser }> = ({ user: pUser }) => {
  const navigate = useNavigate();
  const { user } = useUser(pUser);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [userAvatar, setUserAvatar] = React.useState<string | null>(null);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (user?.profile?.avatar)
      getPicture(user?.profile?.avatar, 'small')
        .then((data) => {
          if (data?.data) setUserAvatar(data?.data);
        })
        .catch((e) => {
          console.log(e);
        });
  }, [user?.profile?.avatar]);

  const handleLogout = () => {
    dispatch(removeUser());
    setOpenMenu(false);
    if (typeof window !== 'undefined') window.location.reload();
  };
  const handleMyTicket = () => {
    navigate('/my-tickets')
  };

  const Buttons = !user ? (
    <div className="space-x-4 flex items-center">
      <button
        className="space-x-4 text-white font-normal hidden md:inline-block"
      >
        <a href="mailto:contact@doorlabs.io" target="_blank" rel="noreferrer">Contact</a>
      </button>
      <button
        onClick={() => navigate('/sign-in')}
        className="text-primary-600 border rounded-lg px-5 py-2 bg-white text-sm"
      >
        Sign In
      </button>
    </div>
  ) : (
    <div className="space-x-4 flex items-center">
      <button
        className="space-x-4 text-white font-normal hidden md:inline-block"
      >
        <a href="mailto:contact@doorlabs.io" target="_blank" rel="noreferrer">Contact</a>
      </button>
      <DropdownMenu
        button={
          <button
            className="flex items-center space-x-1  md:space-x-2 text-white"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {!userAvatar ? (
              <div className="relative inline-flex items-center justify-center w-8 md:w-10 h-8 md:h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {`${user?.profile?.lastName?.[0] || ''}${
                    user?.profile?.firstName?.[0] || ''
                  }`.toLocaleUpperCase()}
                </span>
              </div>
            ) : (
              <img
                className="w-8 md:w-10 h-8 md:h-10 rounded-full"
                src={userAvatar}
                alt="User avatar"
              />
            )}
            <span className="capitalize text-xs text-white md:text-sm w-32">
              {`${user?.profile?.lastName || ''} ${
                user?.profile?.firstName || ''
              }`}
            </span>
            <span
              style={{
                transform: openMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <ICArrowDown />
            </span>
          </button>
        }
        list={[{ label: 'Logout', onClick: handleLogout }, { label: 'My Ticket', onClick: handleMyTicket }]}
        open={openMenu}
      />
    </div>
  );

  return (
    <nav
      id="tn-header"
      className="fixed w-full bg-primary-500 border-b-[0.25px] px-2.5 xl:px-32 lg:px-14 py-3 lg:py-3 z-20"
    >
      <div className="mx-auto flex items-center justify-between">
        <div className="flex items-center gap-10">
          <div className="mr-2">
            <button onClick={() => navigate('/')}>
              <img
                src={'/LogoBright.png'}
                alt="logo"
                className="mt-2 w-[150px] h-[30px] md:w-[180px] md:h-[40px]"
              />
            </button>
          </div>
          <button
            onClick={() => navigate('/')}
            className="mt-2 text-white font-normal hidden md:inline-block"
          >
            Home
          </button>
          <button
            onClick={handleMyTicket}
            className="mt-2 text-white font-normal hidden md:inline-block"
          >
            My Ticket
          </button>
        </div>
        {Buttons}
      </div>
    </nav>
  );
};

export default NavBar;
