import Banner from 'src/components/home/Banner';
import Events from 'src/components/home/Events';

export default function Home() {
  return (
    <div>
      <Banner />
      <div className="px-2.5 xl:px-32 lg:px-14 py-16">
        <div className="text-black text-3xl	font-bold mb-5">UPCOMING EVENTS</div>
        <Events upcoming={true} />
      </div>
      <hr />
      <div className="px-2.5 xl:px-32 lg:px-14 py-16">
        <div className='flex justify-between items-center mb-5'>
          <div className="text-black text-3xl	font-bold">ALL EVENTS</div>
          <a href="/events" className="text-primary-500 text-base font-semibold">View all</a>
        </div>
        <Events upcoming={false} limit={4} />
      </div>
      {/* <Partners />
      <Team /> */}
    </div>
  );
}
