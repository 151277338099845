import * as yup from 'yup';

export const LoginFormValidation = yup.object().shape({
  password: yup
    .string()
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   'Must have at least 8 characters containing 1 uppercase, 1 lowercase, 1 number and 1 special character',
    // )
    .required(),
  email: yup.string().email().required(),
});
