import Container from 'src/components/Container';
import PurchaseTicket from './components/PurchaseTicket';

function PurchaseTicketPage() {
  return (
    <Container>
      <PurchaseTicket />
    </Container>
  );
}

export default PurchaseTicketPage;
