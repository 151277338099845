import * as yup from 'yup';

export const RegistrationFormValidation = yup.object().shape({
  fullName: yup
    .string()
    .required('Full name is required')
    .matches(/(\w.+\s).+$/, 'Full name must contain first name and last name'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must have at least 8 characters containing 1 uppercase, 1 lowercase, 1 number and 1 special character',
    )
    .required('Password is required'),
  phoneNumber: yup
    .string()
    .matches(
      /^\(\+\d{1,3}\)\d{1,14}$/,
      'Must include country code and no spaces allowed',
    )
    .required('Phone Number is required'),
  retypedPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Retyped password is required'),
  email: yup.string().email().required('Email is required'),
});
