import { ICStar } from 'src/lib/icons';
import SignInForm from './components/SignInForm';
import { useNavigate } from 'react-router-dom';

export default function SignIn() {
  const navigate = useNavigate();
  return (
    <div className="mx-0 w-screen min-h-screen">
      <div className="flex flex-row w-full">
        <div
          className="w-0 lg:w-1/2 xl:w-2/3 bg-primary-500 text-white px-0 py-0 bg-no-repeat	bg-center bg-cover hidden lg:flex"
          style={{ backgroundImage: `url('./signin-banner.svg')` }}
        >
          <div className="mx-auto mt-40">
            <ICStar />
            <div
              className="text-5xl xl:text-6xl font-bold mt-12"
              style={{ lineHeight: '77px' }}
            >
              Experience the <br /> excitement, buy <br /> your tickets today
            </div>
            <p className="text-xl xl:text-2xl text-white font-normal mt-5" >
              So don't wait, buy your tickets now and get ready <br />
              to have an exciting time!
            </p>
          </div>
        </div>
        <div className="flex flex-row w-full lg:w-1/2 xl:w-1/3 h-screen justify-center mt-10 lg:mt-20 px-5 lg:px-0">
          <div className="w-96">
            <button onClick={() => navigate('/')}>
              <img
                src="LogoDark.png"
                alt="logo"
                className="h-[40px] md:h-[50px] w-[180px] md:w-[220px]"
              />
            </button>
            <h2 className="mt-6 text-2xl font-semibold leading-9 tracking-tight text-gray-900">
              Log in
            </h2>
            <p className="mt-2.5 text-base text-grey-600 font-normal mb-6">
              Welcome back! Please enter your details.
            </p>
            <SignInForm />
          </div>
        </div>
      </div>
    </div>
  );
}
