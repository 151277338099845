import baseApi from '../axios';

export const getPicture = async (
  fileKey: string,
  size: 'small' | 'medium' | 'large',
) => {
  const resp = await baseApi.get(
    `/storage/link?keyName=${fileKey}&size=${size}`,
  );
  return resp?.data;
};
